<template>
  <v-col cols="12" class="px-2" style="padding-top: 50px">
    <v-slider
      :hint="getHint()"
      :label="getLabel()"
      thumb-label="always"
      thumb-color="secondary"
      :step="field.step || 1"
      :min="field.min || 0"
      :max="field.max || 100"
      v-model="(value || field.obj || obj)[field.field]"
    ></v-slider>
  </v-col>
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "NumberDialComponent",
  mixins: [InputMixin]
};
</script>
