export const InputMixin = {
  props: ["field", "obj", "value", "rules", "readonly", "refName"],
  data() {
    return {
      compoundFormTypes: {
        array: "add-to-array",
        object: "add-to-object",
        userInputs: "user_inputs",
        callbacksConfig: "callbacks_config"
      }
    };
  },
  computed: {
    fieldType() {
      return this.field.type;
    },
    isArrayForm() {
      return [
        this.compoundFormTypes.array,
        this.compoundFormTypes.callbacksConfig,
        this.compoundFormTypes.userInputs
      ].includes(this.fieldType);
    },
    isObjectForm() {
      return [this.compoundFormTypes.object].includes(this.fieldType);
    },
    isUserInputsForm() {
      return [this.compoundFormTypes.userInputs].includes(this.fieldType);
    }
  },
  methods: {
    kvTransformer(f, v) {
      const out = {};
      out[this.field[f].field] = v;
      return out;
    },
    getDisabled(disabled) {
      if (disabled !== undefined) return disabled;
      if (this.field.disabled !== undefined) return this.field.disabled;
      return false;
    },
    getReadonly(readonly) {
      if (readonly !== undefined) {
        return readonly;
      }
      if (this.field.readonly === undefined && this.value === undefined) {
        return false;
      }
      if (this.field.readonly !== undefined) {
        return this.field.readonly;
      }
      if (this.value !== undefined) {
        return false;
      }
      return true;
    },
    getDefaultFieldType() {
      return this.field.valType || "text";
    },
    getOptionConfigByOptionNameAndConfigName(optionName, configName) {
      if (
        this.optionsConfig &&
        optionName &&
        this.optionsConfig[optionName] &&
        this.optionsConfig[optionName][configName]
      ) {
        return this.optionsConfig[optionName][configName];
      }
    },
    getLabel(optionName) {
      let label;
      const conf = this.getOptionConfigByOptionNameAndConfigName(
        optionName,
        "label"
      );
      if (conf) {
        label = conf;
      }
      if (!label) {
        label = this.field.label || this.field.name;
      }
      return label;
    },
    getHint(optionName) {
      let hint;
      const conf = this.getOptionConfigByOptionNameAndConfigName(
        optionName,
        "hint"
      );
      if (conf) {
        hint = conf;
      }
      if (!hint) {
        hint = this.field.hint || this.field.name;
      }
      return hint;
    },
    getRules(optionName) {
      let rules = [];
      const conf = this.getOptionConfigByOptionNameAndConfigName(
        optionName,
        "rule"
      );
      if (conf) {
        rules = conf;
      }
      if (Array.isArray(this.field.rules)) {
        rules = rules.concat(rules, this.field.rules);
      }

      if (this.rules !== undefined) {
        rules = rules.concat(rules, this.rules);
      }
      if (this.field.ruleParams === undefined) {
        return rules;
      }

      if (this.field.ruleParams.includes("required")) {
        rules.push((value) => !!value || "Required.");
      }
      return rules;
    },
    addToArray(formRef) {
      const form = this.$refs[formRef];
      if (!form.validate()) {
        return;
      }
      let row = {};
      for (let i = 0; i < this.field.fields.length; i++) {
        const ff = this.field.fields[i];
        const val = this.field.val[ff.field];
        row[ff.field] = val;
        if (ff.type === "v-textarea" && val) {
          row[ff.field] = val.split("\n");
        }
        this.$set(this.field.val, ff.field, ff.default);
      }
      this.addToArrayHelper(row);
    },
    addToArrayHelper(row) {
      const fieldName = this.field.field;
      if (this.field.obj) {
        if (!this.field.obj[fieldName]) {
          this.field.obj[fieldName] = [];
        } else {
          this.field.obj[fieldName].push(row);
        }
      } else if (this.obj) {
        if (!this.obj[fieldName]) {
          this.obj[fieldName] = [];
        } else {
          this.obj[fieldName].push(row);
        }
      }
    },
    removeFromArray(idx) {
      this.removeFromArrayHelper(idx);
      this.$forceUpdate();
    },
    removeFromArrayHelper(idx) {
      const fieldName = this.field.field;
      if (this.field.obj[fieldName]) {
        this.field.obj[fieldName].splice(idx, 1);
      } else if (this.obj[fieldName]) {
        this.obj[fieldName].splice(idx, 1);
      }
    },
    addToObject(formRef, split = false, processFunc) {
      const form = this.$refs[formRef];
      if (!form.validate()) {
        return;
      }

      const key = this.kv[this.field.key.field];
      let val = this.kv[this.field.val.field];
      if (processFunc !== undefined) {
        val = processFunc(key, val);
      }
      if (split) {
        val = val.split(",").map((s) => s.trim());
      }

      this.obj[this.field.field][key] = val;

      this.$set(this.kv, this.field.key.field, null);
      this.$set(this.kv, this.field.val.field, null);
    },
    removeFromObject(key) {
      delete this.obj[this.field.field][key];
      this.$forceUpdate();
    },
    getAddFunc() {
      switch (this.field.type) {
        case this.compoundFormTypes.callbacksConfig:
          return this.addToCallbackConfigArray;
        case this.compoundFormTypes.object:
          return this.addToObject;
        default:
          return this.addToArray;
      }
    },
    getRemoveFunc() {
      if (this.isArrayForm) {
        return this.removeFromArray;
      }
      if (this.isObjectForm) {
        return this.removeFromObject;
      }
    },
    addToCallbackConfigArray(formRef) {
      const form = this.$refs[formRef];
      if (!form.validate()) {
        return;
      }
      const row = {
        conditions: [
          {
            field: {
              field_path: this.field.val.field_path,
              value: this.field.val.value,
              op: "=="
            }
          }
        ],
        payload: {
          email_content: this.field.val.email_content
        }
      };

      this.field.val.field_path = null;
      this.field.val.value = null;
      this.field.val.email_content = null;

      if (this.obj[this.field.field] === undefined) {
        this.obj[this.field.field] = [];
      }
      this.obj[this.field.field].push(row);
    }
  }
};
