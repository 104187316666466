<template>
  <v-container>
    <v-row class="pa-5">
      <v-col v-for="app in apps" :key="app.title" class="col-sm-4">
        <AppTile :appDetails="app" />
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>
<script>
import AppTile from "@/components/AppTile.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppTiles",
  components: { AppTile },
  data() {
    return {};
  },
  computed: {
    apps() {
      return this.getApps();
    }
  },
  async created() {
    await this.fetchApps();
    this.$forceUpdate();
  },
  methods: {
    ...mapActions("apps", ["fetchApps"]),
    ...mapGetters("apps", ["getApps"])
  }
};
</script>
