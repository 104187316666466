<template>
  <v-footer app fixed class="text-center" color="primary" padless>
    <v-row class="pa-0 fill-height">
      <v-col class="col-md-7" align="right">
        <img
          :src="require('../assets/images/logo_telescope_w.png')"
          style="vertical-align: middle"
          height="20"
        />
        by
        <a href="https://apiobuild.com" target="_blank">
          <img
            :src="require('../assets/images/logo.png')"
            height="20"
            style="vertical-align: middle; margin-top: 0.5px"
          />
        </a>
      </v-col>
      <v-col align="right">
        <v-btn color="primary" depressed tile text small>
          <a
            class="px-5"
            href="https://apiobuild.com/terms-of-service/"
            style="color: black; text-decoration: none"
            target="_blank"
          >
            Terms of Service
          </a>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "CreditFooter"
};
</script>
