<template>
  <v-container class="px-1">
    <CompoundFormInputFields
      :refName="refName"
      :field="field"
      :obj="obj"
      :clickToAddFuncArgs="[
        `add-${refName}`,
        field.split,
        field.inputProcessFunc
      ]"
    />
  </v-container>
</template>

<script>
import CompoundFormInputFields from "@/components/config/form/CompoundFormInputFields.vue";

export default {
  name: "CompoundForm",
  props: ["field", "obj", "refName"],
  components: {
    CompoundFormInputFields
  }
};
</script>
