<template>
  <v-container>
    <v-stepper :value="step" alt-labels>
      <v-stepper-header>
        <v-stepper-step editable step="1"> Create Sheet </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="2"> Populate Data </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="3"> Share </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="4"> Authorize </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <v-row>
              <v-col class="col-12 col-sm-8">
                Create new google sheet as {{ sheet.name }}. Click
                <v-icon>fa-plus</v-icon> on the right to create.
              </v-col>
              <v-col class="col-12 col-sm-4 text-end">
                <v-btn
                  outlined
                  color="green"
                  small
                  dark
                  fab
                  @click="goToNewSheet()"
                >
                  <v-icon>fa-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-col class="text-center">
            <v-btn color="primary" @click="step += 1">
              <v-col> Next </v-col>
              <v-icon>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <v-row>
              <v-col class="col-12 col-sm-8">
                Paste example data to your sheet. Click
                <v-icon>fa-copy</v-icon> on the right to copy.
              </v-col>
              <v-col class="col-12 col-sm-4 text-end">
                <v-btn
                  outlined
                  color="green"
                  small
                  dark
                  fab
                  @click="copyExampleData()"
                >
                  <v-icon>fa-copy</v-icon>
                </v-btn>
              </v-col>
              <v-col class="col-12">
                <v-data-table
                  :headers="exampleDataToTableHeader(sheet.example[0])"
                  :items="exampleDataToTableItems(sheet.example)"
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <v-col class="text-center">
            <v-btn color="primary" @click="step += 1">
              <v-col> Next </v-col>
              <v-icon>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-container>
            <v-row>
              <v-col>
                Click to copy the following email address:
                <v-col class="col-12" no-gutters>
                  <v-text-field
                    v-model="waitressRole"
                    label="Click to copy waitress role"
                    dense
                    readonly
                    full-width
                    color="green"
                    :append-icon="waitressRoleCopied"
                    @click="copyWaitressRole()"
                  ></v-text-field>
                </v-col>
                Click <v-btn color="green" small dark>Share</v-btn> on your
                sheet and add it as an editor.
              </v-col>
            </v-row>
          </v-container>
          <v-col class="text-center">
            <v-btn color="primary" @click="step += 1">
              <v-col> Next </v-col>
              <v-icon>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-container>
            <div class="alert-container">
              <v-alert
                type="success"
                transition="slide-y-transition"
                dismissible
                outlined
                border="left"
                :value="authSheetSuccess"
              >
                <v-row class="title"> Authorized! It's all yours! </v-row>
              </v-alert>
            </div>
            <div class="alert-container">
              <v-alert
                type="error"
                transition="slide-y-transition"
                :value="authSheetHitASnag"
                dismissible
                outlined
                border="left"
              >
                <v-row class="title"> Oops, we hit a snag! </v-row>
                <v-row class="py-3">
                  You might want to check again if you shared the google sheet
                  in step 2 or delete the meta tab on your sheet and try again.
                </v-row>
                <v-row> {{ error.error }} </v-row>
                <v-row v-if="error.message">
                  Details: {{ error.message }}
                </v-row>
              </v-alert>
            </div>
            <v-row>
              <v-col>
                Copy google sheet URL and click <v-icon>fa-unlock</v-icon> to
                authorize.
                <v-form
                  ref="WaitressAuthorizeForm"
                  v-model="WaitressAuthorizeFormValid"
                >
                  <v-row no-gutters class="py-5">
                    <v-col class="col-10" no-gutters>
                      <v-text-field
                        outlined
                        flat
                        label="Google Sheet URL"
                        hint="Copy google sheet url from browser"
                        :rules="[rules.required]"
                        v-model="sheetUrl"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="no-gutters">
                      <v-btn
                        outlined
                        tile
                        height="56"
                        color="secondary"
                        :disabled="authorizing"
                        @click="authorizeSheet()"
                      >
                        <div v-if="!authorizing">
                          <v-icon>fa-unlock</v-icon>
                        </div>
                        <div v-if="authorizing">
                          <v-icon class="spinner">fa-spinner</v-icon>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { WaitressMixin } from "@/lib/WaitressMixin.js";

export default {
  name: "WaitressNewSheetFlow",
  props: ["sheet"],
  mixins: [WaitressMixin],

  data() {
    return {
      step: 1,

      // input
      sheetUrl: "",
      rules: {
        required: (value) => !!value || "Required."
      },

      // display toggles
      error: {},
      WaitressAuthorizeFormValid: false,
      waitressRoleCopied: "",
      authSheetSuccess: false,
      authSheetHitASnag: false
    };
  },
  methods: {
    async authorizeSheet() {
      if (!this.$refs.WaitressAuthorizeForm.validate()) {
        return;
      }

      this.setSheetId(this.sheetUrl);
      await this.waitressAuthSheet();

      // set statuses
      this.authorizing = false;
      // eslint-disable-next-line
      this.sheet.authorized = true;
    },
    copyExampleData() {
      const rows = [];
      for (let ix = 0; ix < this.sheet.example.length; ix += 1) {
        rows.push(this.sheet.example[ix].join("\t"));
      }
      navigator.clipboard.writeText(rows.join("\n"));
    },
    async copyWaitressRole() {
      // copy waitress role
      navigator.clipboard.writeText(this.waitressRole);
      this.waitressRoleCopied = "fa-check";

      // check icon disappear after 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.waitressRoleCopied = "";
    },
    showAuthSuccess() {
      this.authSheetSuccess = true;
      this.authSheetHitASnag = false;
      this.$forceUpdate();

      // NOTE: important to keep it here, callback function could use url
      // eslint-disable-next-line
      this.sheet.url = this.sheetUrl;
      this.sheet.callback(this.sheet.step);
    },
    showAuthHitASnag(err) {
      this.error = {
        error: err,
        message: err.response.data.message
      };
      this.authSheetSuccess = false;
      this.authSheetHitASnag = true;
      this.$forceUpdate();
    }
  }
};
</script>
