<template>
  <v-checkbox
    class="pa-1 py-0"
    dense
    :readonly="getReadonly(readonly)"
    :disabled="getDisabled(disabled)"
    :label="getLabel()"
    :hint="getHint()"
    :rules="getRules()"
    v-model="(value || field.obj || obj)[field.field]"
  />
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "CheckboxComponent",
  mixins: [InputMixin],
  props: ["fieldName", "disabled"]
};
</script>
