<template>
  <v-container>
    <v-btn
      fab
      color="info"
      tile
      absolute
      height="57"
      width="150"
      :style="{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }"
      @click="unblurredFunc()"
      v-if="!fetched"
    >
      View {{ ConfigTypeLookUp[configName] }}s
    </v-btn>
    <v-container
      v-bind:class="{ blurred: !fetched }"
      :disabled="!fetched"
      fluid
      full-width
    >
      <v-card :disabled="!fetched">
        <v-expansion-panels multiple focusable>
          <v-expansion-panel
            v-for="object in objects"
            :key="getId(object)"
            @click="$forceUpdate()"
          >
            <v-expansion-panel-header>
              <v-row class="text-center" align="center" justify="center">
                <v-col class="col-1">
                  <v-chip
                    class="ma-2"
                    color="success"
                    v-if="newId === getId(object)"
                  >
                    New
                  </v-chip>
                </v-col>
                <v-col class="col-12 col-sm-2 text-center">
                  {{ getObjectName(object) }}
                </v-col>
                <v-col class="col-12 col-sm-5">
                  {{ getId(object) }}
                </v-col>
                <v-col>
                  <v-btn
                    outlined
                    small
                    color="info"
                    :href="getObjectUrl(object)"
                    target="_blank"
                  >
                    Go To {{ ConfigTypeLookUp[configName] }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ChopinConfigureStore
                v-if="configName === Chopin"
                :objectId="getId(object)"
              />
              <GoldfishConfigurePage
                v-if="configName === Goldfish"
                :objectId="getId(object)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-col cols="12" :disabled="!fetched" class="text-center mt-5">
        <v-btn
          large
          color="success"
          @click="createNew()"
          :to="createNewUrl"
          :disabled="!fetched"
        >
          Add New {{ ConfigTypeLookUp[configName] }}
          <v-icon right>fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-container>
  </v-container>
</template>

<style>
.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}
</style>

<script>
import ChopinConfigureStore from "@/components/app/ChopinConfigureStore.vue";
import GoldfishConfigurePage from "@/components/app/GoldfishConfigurePage.vue";

export default {
  name: "ConfigMain",
  components: { ChopinConfigureStore, GoldfishConfigurePage },
  props: [
    "configName",
    "createNewUrl",
    "createNewFunc",
    "newId",
    "objects",
    "fetched",
    "unblurredFunc",
    "objectIsId",
    "getObjectId",
    "getObjectName",
    "getObjectUrl"
  ],
  data() {
    const Chopin = "Chopin";
    const Goldfish = "Goldfish";
    const ChopinConfigName = "Store";
    const GoldfishConfigName = "Page";
    return {
      ConfigTypeLookUp: {
        Chopin: ChopinConfigName,
        Goldfish: GoldfishConfigName
      },
      Chopin,
      Goldfish,
      ChopinConfigName,
      GoldfishConfigName
    };
  },
  methods: {
    createNew() {
      if (this.createNewFunc === undefined) {
        return;
      }
      this.createNewFunc();
    },
    getId(object) {
      if (this.objectIsId) {
        return object;
      }
      return this.getObjectId(object);
    }
  }
};
</script>
