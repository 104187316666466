<template>
  <!-- TODO: update options to be consistent with items -->
  <v-select
    dense
    outlined
    flat
    persistent-hint
    :items="field.options"
    :readonly="getReadonly(readonly)"
    :disabled="getDisabled(disabled)"
    :label="getLabel()"
    :hint="getHint()"
    :rules="getRules()"
    v-model="(value || field.obj || obj)[field.field]"
  />
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "SelectComponent",
  mixins: [InputMixin],
  props: ["disabled"]
};
</script>
