import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { postItApiUrl } from "@/lib/utl.js";

Vue.use(VueAxios, axios);

const state = () => ({
  Emails: ["user1@email.com", "user2@email.com", "user3@email.com"],
  NewEmailId: "store1",
  EmailsFetched: false
});
const getters = {
  emails: (state) => {
    return state.Emails;
  },
  emailsFetched: (state) => {
    return state.EmailsFetched;
  },
  newEmailId: (state) => {
    return state.NewEmailId;
  }
};
const mutations = {
  updateEmails(state, emails) {
    state.Emails = emails;
  },
  updateNewEmailId(state, email) {
    state.NewEmailId = email;
  }
};
const actions = {
  async fetchEmails({ commit, state, rootGetters }) {
    if (state.EmailsFetched === true) {
      return;
    }
    try {
      const result = await Vue.axios.get(
        postItApiUrl,
        rootGetters["user/getUserATokenAuthHeader"]
      );
      commit("updateEmails", result.data.email);
      state.EmailsFetched = true;
    } catch (err) {
      if (err.response.status === 404) {
        commit("updateEmails", []);
        state.EmailsFetched = true;
      } else {
        throw new Error(`Error fetching emails, error: ${err}`);
      }
    }
  },
  async addWatch({ commit, rootGetters }, payload) {
    try {
      const result = await Vue.axios.post(
        postItApiUrl,
        payload,
        rootGetters["user/getUserATokenAuthHeader"]
      );
      commit("updateNewEmailId", result.data.email[0]);
    } catch (err) {
      throw new Error(`Error adding email, error: ${err}`);
    }
  },
  async deleteWatch({ rootGetters }, payload) {
    const option = {
      headers: {
        Authorization: `bearer ${rootGetters["user/getUserAToken"]}`
      },
      data: payload
    };

    try {
      await Vue.axios.delete(postItApiUrl, option);
    } catch (err) {
      throw new Error(`Error delete email, error: ${err}`);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
