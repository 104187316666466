<template>
  <v-container full-height>
    <v-row style="height: 10vh"> </v-row>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-btn
          x-large
          color="info"
          href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-0A7445692L223825DMCJSC7I"
          target="_blank"
        >
          Set up Paypal Subscription
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Billing",
  data() {
    return {};
  }
};
</script>
