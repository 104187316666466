<template>
  <v-container class="text-center">
    There's no configuration available for this app.
  </v-container>
</template>

<script>
export default {
  name: "NoAppConfigure"
};
</script>
