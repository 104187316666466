<template>
  <v-app>
    <Sidebar />
    <v-main app>
      <UpdateAvailable />
      <Notification />
      <router-view />
    </v-main>
    <CreditFooter />
  </v-app>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.v-application {
  font-family: "Roboto", sans-serif;
}
</style>

<script>
import CreditFooter from "@/components/CreditFooter.vue";
import Sidebar from "@/components/Sidebar.vue";
import UpdateAvailable from "@/components/UpdateAvailable.vue";
import Notification from "@/components/Notification.vue";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    UpdateAvailable,
    Notification,
    CreditFooter,
    Sidebar
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("apps", ["fetchApps"])
  }
};
</script>
