import { mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export const GoldfishMixin = {
  data() {
    return {
      trampoline: process.env.VUE_APP_TRAMPOLINE_SERVICE_URL
    };
  },
  computed: {
    ...mapGetters("user", ["user", "getUserATokenAuthHeader"])
  },
  methods: {
    pageUrl(page) {
      let url = `${this.trampoline}/router/goldfish/about/page/${this.user.sub}/${page.id}`;
      return url;
    },
    async CreateNewPage() {
      const resp = await Vue.axios.post(
        `${this.trampoline}/router/goldfish/about`,
        {},
        this.getUserATokenAuthHeader
      );
      return resp;
    }
  }
};
