<template>
  <v-autocomplete
    auto-select-first
    clearable
    outlined
    dense
    :readonly="readonly"
    :label="getLabel()"
    :hint="getHint()"
    :rules="getRules()"
    :items="field.items"
    :multiple="field.multiple"
    :small-chips="field.chips"
    deletable-chips
    v-model="(value || field.obj || obj)[field.field]"
    :search-input.sync="searchInput"
    @change="searchInput = ''"
  ></v-autocomplete>
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "ComboboxComponent",
  mixins: [InputMixin],
  data() {
    return {
      searchInput: null
    };
  }
};
</script>
