<template>
  <v-row>
    <v-col class="py-0">
      <v-textarea
        dense
        outlined
        flat
        :readonly="getReadonly()"
        :disabled="getDisabled(disabled)"
        :label="getLabel()"
        :hint="getHint()"
        :height="field.height"
        auto-grow
        :type="getDefaultFieldType()"
        :rules="getRules()"
        v-model="(value || field.obj || obj)[fieldName || field.field]"
        @input="updateObjectValue(field.split)"
        :class="[field.is_markdown ? 'monospace' : 'regular']"
      />
    </v-col>
    <v-col class="py-0" v-if="field.is_markdown && content">
      <v-card tile flat outlined>
        <span style="float: right">
          <v-card-text> Preview </v-card-text>
        </span>
        <v-container>
          <span class="max-image-width-100" v-html="generatePreview()"></span>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<style>
.monospace textarea {
  font-family: monospace;
}
.max-image-width-100 img {
  max-width: 100%;
}
</style>

<script>
import { InputMixin } from "@/lib/InputMixin.js";
import md from "markdown-it";

export default {
  name: "TextareaComponent",
  mixins: [InputMixin],
  props: ["fieldName", "disabled"],
  computed: {
    content() {
      return (this.value || this.field.obj || this.obj)[
        this.fieldName || this.field.field
      ];
    }
  },
  methods: {
    updateObjectValue(split = false) {
      const value = (this.value || this.field.obj || this.obj)[
        this.fieldName || this.field.field
      ];
      let val = value;
      if (split && typeof value === "string") {
        const splitted = value.split(",");
        const existing = splitted
          .slice(0, splitted.length - 1)
          .map((s) => s.trim());
        val = existing.concat([splitted[splitted.length - 1]]);
      }
      this.$set(
        this.value || this.field.obj || this.obj,
        this.fieldName || this.field.field,
        val
      );
    },
    generatePreview() {
      if (!this.content) {
        return "";
      }
      const renderer = md({
        html: true,
        linkify: true,
        typographer: true
      });
      const html = renderer.render(this.content);
      return html;
    }
  }
};
</script>
