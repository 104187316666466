<template>
  <v-container>
    <v-row>
      <v-text-field
        outlined
        flat
        label="Alias"
        hint="An alias for the token."
        v-model="alias"
      >
      </v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        outlined
        flat
        label="Target Audience"
        hint="Where you'll be calling from? If you are testing locally, you could use http://localhost:8080 for example."
        v-model="target_audience"
      >
      </v-text-field>
    </v-row>
    <v-container>
      <v-row justify="center">
        <v-btn fab color="secondary" @click="addToken()">
          <v-icon>fa-plus</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewUserTokenCard",
  data() {
    return {
      alias: "",
      target_audience: ""
    };
  },
  methods: {
    ...mapActions("user", ["addTToken", "fetchTTokens"]),
    addToken() {
      let payload = {
        alias: this.alias,
        target_audience: this.target_audience
      };
      this.addTToken(payload).then(() => {
        this.fetchTTokens();
        this.$forceUpdate();
        this.resetFields();
      });
    },
    resetFields() {
      this.alias = "";
      this.target_audience = "";
    }
  }
};
</script>
