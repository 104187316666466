<template>
  <v-container>
    <AreYouSure :value="updateDialog" :id="objectId" />
    <AreYouSure :value="deleteDialog" :id="objectId" />
  </v-container>
</template>
<script>
import AreYouSure from "@/components/AreYouSure.vue";

export default {
  name: "AreYouSures",
  props: ["objectId", "updateDialog", "deleteDialog"],
  components: {
    AreYouSure
  }
};
</script>
