<template>
  <v-container>
    <v-stepper v-model="step" vertical>
      <template v-for="(n, i) in steps">
        <v-stepper-step
          :complete="step > i + 1"
          :step="i + 1"
          :key="`${i}-step`"
        >
          <!-- :editable="n.editable || false" -->
          {{ n.name }}
        </v-stepper-step>

        <v-stepper-content :step="i + 1" :key="`${i}-content`">
          <v-form :ref="`${i}-StepForm`" v-model="StepFormValid">
            <v-row class="px-3 py-0">
              <v-col cols="12" :key="fi" v-for="(field, fi) in n.fields">
                <v-text-field
                  v-if="field.type === 'v-text-field'"
                  outlined
                  flat
                  :label="field.label"
                  :hint="field.name"
                  v-model="payload[field.field]"
                  :rules="field.rules"
                ></v-text-field>
                <WaitressSheet
                  :sheet="field.sheet"
                  v-if="field.type === 'waitress'"
                />
                <PostIt
                  v-if="field.type === 'post-it'"
                  :callback="field.callback"
                />
                <template v-if="field.type === 'view-and-configure-store'">
                  <!-- TODO: no we've hit a snag handler -->
                  <v-col class="col-12">
                    <v-alert
                      outlined
                      type="error"
                      transition="slide-y-transition"
                      v-if="showCreateStoreError"
                      border="left"
                    >
                      <v-row class="title">Sorry, we've hit a snag!</v-row>
                      <v-row class="py-3">
                        <span>
                          We are not able to create the store. Please see
                          details below or contact our awesome developer at
                          <a
                            href="mailto:apiobuild@gmail.com"
                            target="_blank"
                            style="color: #00bcd4"
                          >
                            apiobuild@gmail.com
                          </a>
                        </span>
                      </v-row>
                      <v-row>{{ createStoreError }}</v-row>
                    </v-alert>
                    <v-alert
                      outlined
                      type="info"
                      transition="slide-y-transition"
                      v-if="creatingStore"
                      border="left"
                    >
                      <v-row>
                        Dudu dudu dudu beep beep ... we are creating your store
                        from outerspace.
                      </v-row>
                    </v-alert>
                    <v-alert
                      outlined
                      type="success"
                      :icon="false"
                      transition="slide-y-transition"
                      v-if="storeCreated"
                      border="left"
                    >
                      <h2 class="text-center">Yay, your store is ready!</h2>

                      <v-col class="col-12 my-5 text-center">
                        <v-btn
                          large
                          fab
                          color="info"
                          @click="goToConfigure(storeId)"
                          light
                          class="mx-5"
                        >
                          <v-icon class="px-1">fa-cog</v-icon>
                        </v-btn>
                        CONFIGURE
                        <v-btn
                          large
                          fab
                          color="primary"
                          :href="storeUrl(storeId)"
                          target="_blank"
                          class="mx-5"
                        >
                          <v-icon class="px-1">fa-paper-plane</v-icon>
                        </v-btn>
                        GO TO STORE
                      </v-col>
                      <v-col class="text-center">
                        <v-btn
                          large
                          color="secondary"
                          light
                          class="mx-5"
                          @click="reload()"
                        >
                          CREATE ANOTHER STORE
                        </v-btn>
                      </v-col>
                    </v-alert>
                  </v-col>
                </template>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="col-12 col-sm-6 text-start">
              <v-btn outlined color="grey" @click="step -= 1" v-if="n.back">
                Back
              </v-btn>
            </v-col>
            <v-col class="col-12 col-sm-6 text-end">
              <v-btn color="primary" @click="next(i)" v-if="n.continue">
                Next
              </v-btn>
            </v-col>
            <v-col class="col-12 text-center">
              <v-btn outlined color="secondary" @click="next(i)" v-if="n.skip">
                Skip (Add Later)
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </template>
    </v-stepper>
  </v-container>
</template>

<script>
import WaitressSheet from "@/components/flow/WaitressSheet.vue";
import PostIt from "@/components/flow/PostIt.vue";
import { ChopinMixin } from "@/lib/ChopinMixin.js";

export default {
  name: "ChopinStoresFlow",
  components: { WaitressSheet, PostIt },
  mixins: [ChopinMixin],
  data() {
    return {
      showCreateStoreError: false,
      createStoreError: null,
      payload: {},
      // NOTE: rules
      rules: {
        max250: (v) =>
          typeof v === "string"
            ? v.length <= 250 || "More than 250 chacters"
            : true,
        required: (v) => !!v || "Required.",
        min: (v) =>
          typeof v === "string" ? v.length >= 0 || "Required" : "Required",
        noat: (v) =>
          typeof v === "string"
            ? v.length >= 0 || "Remove @ from the beginning"
            : true
      },

      StepFormValid: false,
      step: 1
    };
  },
  computed: {
    steps() {
      return [
        {
          name: "Name of Your Store",
          continue: true,
          fields: [
            {
              name: "(Required) Store Name. You may not change this aftewards.",
              label: "Store Name. You may not change this aftewards.",
              type: "v-text-field",
              field: "name",
              rules: [this.rules.required, this.rules.min]
            }
          ]
        },
        {
          name: "Add Catalog Google Sheet",
          fields: [
            {
              type: "waitress",
              sheet: {
                name: "Catalog",
                authorized: false,
                example: [
                  [
                    "name",
                    "nickname",
                    "description",
                    "image_url",
                    "price",
                    "max_qty",
                    "category",
                    "hide",
                    "no_tax"
                  ],
                  [
                    "sku-1",
                    "Cool Cat",
                    null,
                    "https://media.giphy.com/media/3o6Zt481isNVuQI1l6/giphy.gif",
                    10
                  ],
                  [
                    "sku-2",
                    "Funny Cat",
                    null,
                    "https://media.giphy.com/media/l6Td5sKDNmDGU/giphy.gif",
                    5
                  ]
                ],
                url: null,
                step: 1,
                callback: this.next
              }
            }
          ]
        },
        {
          name: "Add Order Google Sheet",
          fields: [
            {
              type: "waitress",
              sheet: {
                name: "Order",
                authorized: false,
                example: [
                  [
                    "order_number",
                    "order_number_short",
                    "user_name",
                    "user_email",
                    "user_phone",
                    "user_address",
                    "order_created",
                    "order_created_tz",
                    "order_subtotal",
                    "order_shipping_option",
                    "order_shipping",
                    "order_tax_rate",
                    "order_tax",
                    "order_total",
                    "product_name",
                    "product_nickname",
                    "product_qty",
                    "product_price",
                    "payment_method"
                  ]
                ],
                url: null,
                step: 2,
                callback: this.next
              }
            }
          ]
        },
        {
          name: "(Optional) Add Email Confirmation",
          skip: true,
          fields: [
            {
              type: "post-it",
              callback: this.nextToCreateStore
            }
          ]
        },
        {
          name: "View and Configure Store",
          fields: [
            {
              type: "view-and-configure-store"
            }
          ]
        }
      ];
    }
  },
  methods: {
    next(i) {
      if (!this.$refs[`${i}-StepForm`][0].validate()) {
        return;
      }
      this.step += 1;

      if (this.step === this.steps.length) {
        this.nextAndCreateStore();
      }
    },
    nextToCreateStore() {
      this.next(this.steps.length - 1);
    },
    alertCreateStoreError(err) {
      this.showCreateStoreError = true;
      this.createStoreError = err;
    },
    hideCreateStoreError() {
      this.showCreateStoreError = false;
      this.createStoreError = null;
      this.$forceUpdate();
    },
    nextAndCreateStore() {
      const catalogUrl = this.steps[1].fields[0].sheet.url;
      const orderUrl = this.steps[2].fields[0].sheet.url;

      this.CreateStore(
        this.payload,
        catalogUrl,
        orderUrl,
        this.alertCreateStoreError
      );
    },
    reload() {
      window.location.reload();
    }
  }
};
</script>
