<template>
  <v-combobox
    dense
    outlined
    flat
    persistent-hint
    :loading="loading"
    :items="getItems()"
    :readonly="getReadonly(readonly)"
    :disabled="getDisabled(disabled)"
    :label="getLabel()"
    :hint="getHint()"
    :rules="getRules()"
    :return-object="false"
    v-model="(value || field.obj || obj)[field.field]"
  >
    <template v-slot:progress>
      <v-progress-linear
        color="blue"
        :indeterminate="false"
        :value="50"
      ></v-progress-linear>
    </template>
  </v-combobox>
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "ComboboxWithLoad",
  mixins: [InputMixin],
  props: ["disabled", "disableDeprecatedMethodsOnly"],
  data() {
    return {
      loading: true,
      itemsVal: []
    };
  },
  async created() {
    const items = await this.field.getItemsFn();
    this.loading = false;
    this.itemsVal = items;
  },
  methods: {
    getItems() {
      const items = this.itemsVal.map((x) => {
        x.text = x.name;
        x.value = x.name;

        if (this.field.disableDeprecatedMethodsOnly) {
          const computed = {
            text: x.name,
            value: x.name,
            disabled: x.tab === "Deprecated"
          };
          return computed;
        }

        const computed = {
          text: x.name,
          value: x.name,
          disabled:
            x.tab === "Deprecated" || x.name === "Manual" || x.name === "Stripe"
        };
        return computed;
      });
      return items;
    }
  }
};
</script>
