<template>
  <div>
    <v-container v-if="updateExists">
      <v-alert text color="info">
        <v-row no-gutters align="center" justify="center">
          <v-col> New Update Available! </v-col>
          <v-col align="right" justify="right">
            <v-btn outlined color="info" @click="refreshApp"> Update </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "UpdateAvailable",
  data() {
    return {
      registration: null,
      refreshing: false,
      updateExists: false
    };
  },
  created() {
    document.addEventListener(
      "swUpdated",
      (e) => {
        this.showRefreshUI(e);
      },
      { once: true }
    );
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
};
</script>
