import Vue from "vue";
import Vuex from "vuex";
import apps from "@/store/modules/apps";
import user from "@/store/modules/user";
import chopin from "@/store/modules/chopin";
import postit from "@/store/modules/post-it";
import goldfish from "@/store/modules/goldfish";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "__telescope_user_v2__",
  storage: window.localStorage,
  modules: ["user"]
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { apps, user, chopin, postit, goldfish },
  plugins: [vuexLocal.plugin]
});
