import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import axios from "axios";

export const WaitressMixin = {
  data() {
    return {
      authorizing: false,
      waitressRole: "waitress-dev@apio-277201.iam.gserviceaccount.com",

      route: `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}`,
      sheetId: null
    };
  },
  computed: {
    ...mapGetters("user", ["user", "getUserATokenAuthHeader"])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    waitressApiPolicyRoute(sheetId) {
      return `waitress/gsheets/${sheetId || this.sheetId}`;
    },
    goToNewSheet() {
      window.open("https://docs.google.com/spreadsheets/create", "_blank");
    },
    exampleDataToTableHeader(header) {
      const out = [];
      for (let ix = 0; ix < header.length; ix += 1) {
        out.push({
          text: header[ix],
          value: header[ix]
        });
      }
      return out;
    },
    exampleDataToTableItems(data) {
      const out = [];
      const header = data[0];
      const content = data.slice(1, data.length);
      for (let ix = 0; ix < content.length; ix += 1) {
        const row = content[ix];
        const record = {};
        for (let cx = 0; cx < row.length; cx += 1) {
          record[header[cx]] = row[cx];
        }
        out.push(record);
      }
      return out;
    },
    setSheetId(url) {
      const sheetId = this.extractSheetId(url);
      this.sheetId = sheetId;
    },
    extractSheetId(url) {
      const sheetUrl = url
        .replace("https://", "")
        .replace("docs.google.com/spreadsheets/d/", "");
      const sheetId = sheetUrl.split("/")[0];
      return sheetId;
    },

    async verifySheetOwnerRequest() {
      try {
        let resp = await axios.post(
          `${this.route}/verify/route`,
          {
            route: this.waitressApiPolicyRoute()
          },
          this.getUserATokenAuthHeader
        );
        return resp;
      } catch (err) {
        return err;
      }
    },
    async authSheetPostRequest() {
      let url = `${this.route}/router/waitress/gsheets/auth/${this.sheetId}`;

      try {
        let resp = await Vue.axios.post(
          url,
          { owner: this.user.sub },
          this.getUserATokenAuthHeader
        );
        return resp;
      } catch (err) {
        return err;
      }
    },
    async waitressAuthSheet() {
      await this.auth0GetToken();

      this.authorizing = true;
      const [verify, auth] = await Promise.all([
        this.verifySheetOwnerRequest(),
        this.authSheetPostRequest()
      ]);

      if (!(verify instanceof Error)) {
        if (verify.status == 200 && verify.data.ok === true) {
          this.showAuthSuccess();
          return;
        }
      }
      if (!(auth instanceof Error)) {
        this.showAuthSuccess();
      } else {
        this.showAuthHitASnag(auth);
      }
      return;
    }
  }
};
