<template>
  <v-container v-if="fetched" :id="objectId">
    <UpdateAlert :hitASnag="hitASnag" :error="errorDetail" />
    <AreYouSures
      :objectId="objectId"
      :updateDialog="updateDialog"
      :deleteDialog="deleteDialog"
    />
    <ExpansionPanels ref="panels" :forms="forms" :obj="store" />
    <SaveAndTrash :confirmFunc="confirm" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ConfigureMixin } from "@/lib/ConfigureMixin.js";
import ExpansionPanels from "@/components/config/shared/ExpansionPanels.vue";
import SaveAndTrash from "@/components/config/shared/SaveAndTrash.vue";
import currencies from "@/currencies.json";
import countries from "countries-list";
import "regenerator-runtime/runtime";

export default {
  name: "ChopinConfigureStore",
  mixins: [ConfigureMixin],
  components: {
    ExpansionPanels,
    SaveAndTrash
  },
  data() {
    const checkStringMax = (v, n) => {
      if (typeof v === "string") {
        return v.length <= n || `More than ${n} chacters`;
      }
      return true;
    };
    const USZipcoderRule = (zipcode) => {
      return !isNaN(zipcode) && zipcode.length === 5;
    };
    const CanadaZipcodeRule = (zipcode) => {
      const re = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      if (re.test(zipcode)) {
        return true;
      }
      return false;
    };

    const rules = {
      descriptionMax: (v) => checkStringMax(v, 1000),
      outOfStockTextMax: (v) => checkStringMax(v, 30),
      orderConfirmationEmailSubjectMax: (v) => checkStringMax(v, 100),
      validateEmailAddress: (v) => {
        if (typeof v === "string" && v.length > 0) {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          v = re.test(String(v).toLowerCase());
          if (v === false) {
            return "Invalid email address";
          }
        }
        return true;
      },
      noat: (v) =>
        !(typeof v === "string" && v.length > 0 && v[0] === "@") || "Remove @",
      isValidzipcode: (v) => {
        if (!v) {
          return "Required";
        }
        let splitted = v;
        if (typeof v === "string") {
          splitted = v.split(",");
        }
        if (
          splitted.some((x) => !(USZipcoderRule(x) || CanadaZipcodeRule(x)))
        ) {
          return "Contains Invalid Zipcode";
        }
        return true;
      },
      emailAuthorized: (v) => {
        if (!v || v === false) {
          return true;
        }
        const allowed = this.checkEmailConfirmationEnabled();
        if (allowed) {
          return true;
        }
        return "Authorize gmail access in post-it first";
      },
      hasShippingOptions: (v) => {
        if (!v || v === false) {
          return true;
        }
        if (Object.keys(this.store.shipping).length === 0) {
          return "Must have shipping options to enable free shipping";
        }
        return true;
      }
    };
    const getPostItConditionsElFunc = (el) => {
      return el.conditions[0].field;
    };
    const getPostItPayloadElFunc = (el) => {
      return el.payload;
    };
    return {
      store: {},

      updateDialogCallback: this.updateStoreFromForm,
      deleteDialogCallback: this.deleteStore,

      rules,

      userInputsVal: {
        name: null,
        type: "text-field",
        hide: false,
        required: false,
        description: null
      },
      pagesInputsVal: {
        title: null,
        content: null
      },
      getPostItElFuncs: {
        getPostItConditionsElFunc,
        getPostItPayloadElFunc
      },
      postitCallbacksVal: {
        field_path: null,
        value: null,
        email_content: null
      },
      contactMethod: null,
      contactMethodVal: null,
      paymentMethodInput: {
        name: null,
        handle: null
      },
      shippingOptionsName: null,
      shippingOptionsVal: null,
      shippingAreasName: null,
      shippingAreasVal: null
    };
  },
  async created() {
    await this.fetchStoreDetails({
      storeId: this.objectId
    });
    await this.fetchEmails();

    this.setStoreDetail();
    this.fetched = true;
  },
  computed: {
    ...mapGetters("postit", ["emails", "emailsFetched"]),
    ...mapGetters("user", ["email"]),
    ...mapGetters("chopin", ["storeDetails"]),
    countryNames() {
      const preDefined = Object.values(countries.countries).map((x) => x.name);
      const custom = [
        "Republic of Korea",
        "Macau",
        // USD
        "Bonaire, Sint Eustatius And Saba",
        "Micronesia (Federated States of)",
        "Timor-Leste (East Timor)",
        "United States Minor Outlying Islands",
        "Virgin Islands British",
        "Virgin Islands US",
        // EUR
        "Holy See (Vatican City State)",
        "Reunion",
        "Saint Martin French",
        "Aland Islands",
        // GBP
        "Isle Of Man",
        "South Georgia and The South Sandwich Islands",
        // NOK
        "Svalbard and Jan Mayen Islands",
        // NZD
        "Pitcairn",
        // XAF
        "Congo"
      ];
      return preDefined.concat(custom);
    },

    forms() {
      return [
        {
          name: "Store Info",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/store-configuration/#store-info",
          expand: true,
          fields: [
            {
              label: "Store Name",
              name: "Store Name shown on the top of the store page",
              type: "v-text-field",
              readonly: true,
              field: "name"
            },
            {
              label: "Logo URL",
              name: "(Optional) Logo shown on the top of the store page",
              field: "logo_url",
              type: "v-text-field"
            },
            {
              label: "Store Description (less than 1000 characters)",
              name: "(Optional) Tell customers what the store is about",
              field: "description",
              is_markdown: true,
              type: "v-textarea",
              rules: [this.rules.descriptionMax]
            },
            {
              label: "Announcement",
              name: "(Optional) News to share with your customers",
              field: "announcement",
              type: "v-text-field"
            }
          ]
        },
        {
          name: "Layout",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/store-configuration/#layout",
          fields: [
            {
              label: "Store Background Image Url",
              name: "(Optional) Add a background image for your store",
              field: "background_image_url",
              type: "v-text-field"
            },
            {
              name: "Store Page Theme Color",
              field: "color",
              type: "swatch"
            },
            {
              label: "Page Header Height",
              name: "(Optional) Adjust header (background image) height between 30% to 100% of the screen",
              field: "hero-height",
              type: "number-dial",
              min: 30,
              max: 100,
              step: 5
            },
            {
              name: "Use Light Page Theme (Black Text)",
              field: "no_description_whiteout",
              cols: 4,
              type: "v-checkbox"
            },
            {
              type: "v-select",
              label: "Category Bar Layout",
              name: "Category Bar Layout (Default to enlarge)",
              options: ["hide", "enlarge", "small"],
              cols: 4,
              field: "category_bar_layout"
            },
            {
              label: "Out of Stock Display Text",
              name: "(Optional) Message to show when the product has max_qty is 0",
              cols: 4,
              field: "out_of_stock_text",
              type: "v-text-field",
              rules: [this.rules.outOfStockTextMax]
            },
            {
              type: "v-divider"
            },
            {
              name: "Pages",
              field: "pages",
              type: "add-to-array",
              obj: this.store,
              val: this.pagesInputsVal,
              fields: [
                {
                  type: "v-text-field",
                  field: "title",
                  label: "Page Title",
                  name: "Name of the page.",
                  ruleParams: ["required"]
                },
                {
                  type: "v-text-area",
                  field: "content",
                  label: "Page Content",
                  is_markdown: true,
                  name: "Content of the page in markdown.",
                  ruleParams: ["required"]
                }
              ]
            }
          ]
        },
        {
          name: "Order Settings",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/order-settings/",
          fields: [
            {
              name: "User Input Fields",
              description:
                "Configure existing fields on checkout page. If add new custom fields, the input will be collected on order sheet.",
              field: "user_inputs",
              obj: this.store,
              type: "user_inputs",
              val: this.userInputsVal,
              fields: [
                {
                  field: "name",
                  label: "Field Name",
                  type: "v-text-field",
                  cols: 3,
                  ruleParams: ["required"]
                },
                {
                  field: "type",
                  label: "Field Type",
                  type: "v-select",
                  options: [
                    "textarea",
                    "text-field",
                    "text-field-number",
                    "date-picker",
                    "address"
                  ],
                  default: "text-field",
                  readonly: true,
                  cols: 2
                },
                {
                  field: "hide",
                  name: "Hide",
                  type: "v-checkbox",
                  cols: 1
                },
                {
                  field: "required",
                  name: "Require",
                  type: "v-checkbox",
                  cols: 2
                },
                {
                  name: "Field Description",
                  label: "Field Description",
                  field: "description",
                  type: "v-text-area",
                  height: "80px",
                  disabled: false,
                  cols: 4
                }
              ]
            },
            {
              type: "v-divider"
            },
            {
              name: "Send Order Confirmation",
              label:
                "Send Order Confirmation (Make sure you have post-it Authorized)",
              field: "email_confirmation_enabled",
              type: "v-checkbox",
              rules: [this.rules.emailAuthorized],
              cols: 12
            },
            {
              label: "Order Confirmation Email Subject",
              name: "Email subject for order confirmation email. Use <store_name> and <order_number> to show store name and order number.",
              type: "v-text-field",
              field: "email_subject",
              rules: [this.rules.orderConfirmationEmailSubjectMax]
            },
            {
              name: "Custom Email Messages",
              description:
                "Configure custom email messaging by payment methods.",
              field: "postit_callbacks",
              type: "callbacks_config",
              obj: this.store,
              val: this.postitCallbacksVal,
              fields: [
                {
                  field: "field_path",
                  label: "Field",
                  type: "v-select",
                  getElFunc: this.getPostItElFuncs.getPostItConditionsElFunc,
                  options: [
                    {
                      value: "$.payment.payment_method",
                      text: "Payment Method"
                    }
                  ],
                  cols: 3,
                  ruleParams: ["required"]
                },
                {
                  field: "value",
                  label: "Field Value",
                  type: "v-combobox-with-load",
                  getElFunc: this.getPostItElFuncs.getPostItConditionsElFunc,
                  disableDeprecatedMethodsOnly: true,
                  getItemsFn: this.getPaymentMethods,
                  cols: 3,
                  ruleParams: ["required"]
                },
                {
                  field: "email_content",
                  label: "Email Message",
                  hint: "Custom email message for the payment method. Use <total>, <method>, <handle> to show order total, payment method and your payment method handle.",
                  type: "v-text-area",
                  getElFunc: this.getPostItElFuncs.getPostItPayloadElFunc,
                  cols: 6,
                  ruleParams: ["required"]
                }
              ]
            }
          ]
        },
        {
          name: "Contact Information",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/contact-information/",
          fields: [
            {
              name: "Social Plugins",
              field: "owner_info",
              type: "add-to-object",
              inputProcessFunc: this.processContactInfo,
              key: {
                label: "Contact Method",
                hint: "Contact method type",
                options: [
                  "email",
                  "facebook",
                  "instagram",
                  "line",
                  "phone",
                  "twitter",
                  "whatsapp"
                ],
                field: "key",
                val: this.contactMethod,
                ruleParams: ["required"]
              },
              val: {
                label: "Contact Information",
                readonly: true,
                optionsConfig: {
                  email: {
                    label: "Email Address",
                    hint: "Email address",
                    rule: [this.rules.validateEmailAddress]
                  },
                  facebook: {
                    label: "Link to Facebook page or group"
                  },
                  instagram: {
                    label: "Instagram Handle",
                    hint: "Instagram handle without @"
                  },
                  line: {
                    label: "Line Link",
                    hint: "Link to LINE account or group"
                  },
                  phone: {
                    label: "Phone Number",
                    hint: "Phone number without symbols"
                  },
                  twitter: {
                    label: "Twitter Handle",
                    hint: "Twitter handle without @"
                  },
                  whatsapp: {
                    label: "Whatsapp Number",
                    hint: "Whatsapp number without symbols"
                  }
                },
                type: "v-text-field",
                field: "value",
                val: this.contactMethodVal,
                ruleParams: ["required"]
              }
            }
          ]
        },
        {
          name: "Payments",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/payments/",
          description:
            'Email us at <a href="mailto:apiobuild@gmail.com" target="_blank">apiobuild@gmail.com</a> to add Stripe integration. Fill out <a href="https://github.com/apiobuild/requests/issues/new?assignees=l1990790120&labels=chopin%2Cadd-payment-method&template=add-payment-method.yaml&title=Add+Payment+Method%3A+%3Cname%3E" target="_blank">this request</a> to suggest a new payment method.',
          fields: [
            {
              name: "Store Currency",
              field: "currency",
              cols: 4,
              type: "v-combobox",
              items: currencies
            },
            {
              name: "(Optional) Minimum Order Amount Required",
              label: "Order Minimum",
              field: "order_minimum",
              type: "v-text-field",
              valType: "number",
              cols: 4
            },
            {
              name: "Payment Method",
              field: "other_payment_methods",
              type: "add-to-array",
              obj: this.store,
              val: this.paymentMethodInput,
              fields: [
                {
                  type: "v-combobox-with-load",
                  getItemsFn: this.getPaymentMethods,
                  field: "name",
                  label: "Payment Method Name",
                  name: "Name of the payment method.",
                  cols: 4,
                  ruleParams: ["required"]
                },
                {
                  type: "v-text-field",
                  field: "handle",
                  label: "Payment Method Detail",
                  name: "Your email or phone number. Notes on how to pay you.",
                  cols: 8
                }
              ]
            }
          ]
        },
        {
          name: "Shipping",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/order-settings/#shipping",
          fields: [
            {
              name: "Shipping Options",
              field: "shipping",
              type: "add-to-object",
              key: {
                label: "Shipping Option Name",
                hint: "Shipping Option Name",
                field: "key",
                val: this.shippingOptionsName,
                ruleParams: ["required"]
              },
              val: {
                label: "Shipping Option Cost",
                hint: "Shipping Option Cost",
                type: "v-text-field",
                valType: "number",
                field: "value",
                val: this.shippingOptionsVal,
                ruleParams: ["required"]
              }
            },
            {
              type: "v-divider"
            },
            {
              name: "Delivery Zipcode",
              field: "shipping_areas",
              type: "add-to-object",
              key: {
                label: "Shipping Area Name",
                hint: "Shipping area name",
                type: "v-textarea",
                field: "key",
                val: this.shippingAreasName,
                ruleParams: ["required"]
              },
              val: {
                label: "Shipping Area Zipcodes",
                hint: "Shipping area zipcodes (comma separated)",
                type: "v-textarea",
                field: "value",
                val: this.shippingAreasVal,
                ruleParams: ["required"],
                rules: [this.rules.isValidzipcode],
                split: true
              }
            },
            {
              type: "v-divider"
            },
            {
              name: "Country",
              field: "country-names",
              type: "v-combobox",
              items: this.countryNames,
              multiple: true,
              chips: true
            },
            {
              name: "Offer Free Shipping",
              field: "has_free_shipping_amt",
              type: "v-checkbox",
              rules: [this.rules.hasShippingOptions],
              cols: 4
            },
            {
              name: "Free Shipping Dollar Amount",
              label: "Free Shipping Amount",
              field: "free_shipping_amt",
              type: "v-text-field",
              valType: "number",
              cols: 4
            }
          ]
        },
        {
          name: "Discount",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/order-settings/#discount",
          fields: [
            {
              type: "v-select",
              label: "(Optional) Qualify discount by percent or dollar amount",
              name: "Discount Type",
              options: ["", "percent", "dollars"],
              cols: 4,
              obj: this.store.discount,
              field: "type"
            },
            {
              type: "v-text-field",
              label: "Discount Category",
              name: "(Optional) Apply discount only to this category",
              cols: 4,
              obj: this.store.discount.config,
              field: "category"
            },
            {
              type: "v-divider"
            },
            {
              type: "v-text-field",
              label: "Percent Off",
              name: "(Optional) Discount percent off. Required if seqrlect dollars percent type",
              cols: 4,
              valType: "number",
              obj: this.store.discount.config,
              field: "percent_off"
            },
            {
              type: "v-text-field",
              label: "Dollars Off",
              name: "(Optional) Discount dollars off. Required if seqrlect dollars discount type",
              cols: 4,
              valType: "number",
              obj: this.store.discount.config,
              field: "dollars_off"
            },
            {
              type: "v-divider"
            },
            {
              type: "v-text-field",
              label: "Discount Threshold",
              name: "(Optional) Apply discount to orders over threshold dollars",
              cols: 4,
              valType: "number",
              obj: this.store.discount.config,
              field: "threshold"
            }
          ]
        },
        {
          name: "Tax",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/order-settings/#tax",
          fields: [
            {
              type: "v-text-field",
              label: "Tax Rate",
              name: "(Optional) Tax Rate on a 100 basis. For example: New York State tax rate is 8.875%, enter 8.875.",
              cols: 4,
              valType: "number",
              field: "tax_rate"
            }
          ]
        },
        {
          name: "Waitress API",
          docs: "https://apiobuild.com/docs/docs/apps/chopin/store-configuration/#waitress-api",
          description:
            "These are auto generated when you created this store (If you need to update them, contact us).",
          fields: [
            {
              type: "v-text-field",
              readonly: true,
              label: "Catalog API",
              cols: 12,
              field: "catalog_url"
            },
            {
              type: "v-text-field",
              readonly: true,
              label: "Order API",
              cols: 12,
              field: "order_submit_url"
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions("chopin", [
      "fetchStoreDetails",
      "deleteStore",
      "updateStore",
      "getPaymentMethods"
    ]),
    ...mapActions("postit", ["fetchEmails"]),
    processContactInfo(key, val) {
      if (key !== "facebook") {
        [
          "https",
          "http",
          "://",
          "twitter.com/",
          "instagram.com/",
          "line.me/",
          "www."
        ].forEach((x) => (val = val.replace(x, "")));
        if (key === "instagram") {
          val = val.split(/\/?\?/)[0];
          val = val.replace(/[^\w.]/g, "");
        }
        if (key === "twitter") {
          val = val.replace(/\W/g, "");
        }
      }

      const convertFacebookUrl = (val) => {
        const url = new URL(val);
        url.protocol = "https";
        const deleteSearchParamKeys = [];
        for (let key of url.searchParams.keys()) {
          if (key !== "id") {
            deleteSearchParamKeys.push(key);
          }
        }
        deleteSearchParamKeys.forEach((x) => url.searchParams.delete(x));
        url.hash = "";
        return url.toString();
      };
      if (key === "facebook") {
        try {
          val = convertFacebookUrl(val);
        } catch (err) {
          ["facebook.com/", "www.", "@"].forEach(
            (x) => (val = val.replace(x, ""))
          );
          val = val.split(/\/?\?/)[0];
          val = new URL(val, "https://www.facebook.com/").toString();
        }
      }
      if (key === "line") {
        val = new URL(val, "https://line.me/").toString();
      }
      if (["phone", "whatsapp"].includes(key)) {
        val = val.replace(/\D/g, "");
      }
      return val;
    },
    setStoreDetail() {
      this.store = this.storeDetails[this.objectId];
      if (!this.store.pages) {
        this.store.pages = [];
      }
      if (!this.store.shipping) {
        this.store.shipping = {};
      }
      if (!this.store.other_payment_methods) {
        this.store.other_payment_methods = [];
      }
      if (!this.store.shipping_areas) {
        this.store.shipping_areas = {};
      }
      if (!this.store.discount) {
        this.store.discount = {};
      }
      if (!this.store.discount.config) {
        this.store.discount.config = {};
      }
      if (!this.store.description) {
        this.store.description = "";
      }
      if (!this.store.color) {
        this.store.color = "#FCD236";
      }
      if (!this.store["hero-height"]) {
        this.store["hero-height"] = 50;
      }
    },
    checkEmailConfirmationEnabled() {
      const emails = this.emails;
      return emails.includes(this.store.owner_info.email);
    },
    setCountryCodes() {
      if (
        this.store["country-names"] &&
        this.store["country-names"].length > 0
      ) {
        this.store["country-codes"] = this.store["country-names"];
      } else {
        this.store["country-codes"] = null;
      }
    },
    async updateStoreFromForm() {
      const valid = this.validateInput();
      if (!valid) {
        let err = new Error("Please check your input for store creation.");
        this.alertError(err);
        return;
      }
      this.setCountryCodes();
      // NOTE: just make sure these are null if not set
      const emptyStringToNullFields = [
        {
          field: "email_subject",
          default: null
        },
        {
          field: "order_minimum",
          default: 0
        },
        {
          field: "free_shipping_amt",
          default: 0
        },
        {
          root: this.store.discount.config,
          default: 0,
          field: "dollars_off"
        },
        {
          root: this.store.discount.config,
          default: 0,
          field: "percent_off"
        },
        {
          root: this.store.discount.config,
          default: 0,
          field: "threshold"
        }
      ];
      emptyStringToNullFields.forEach((args) => {
        const store = args.root || this.store;
        if (store[args.field] === "") {
          store[args.field] = args.default;
        }
      });

      const payload = JSON.parse(JSON.stringify(this.store));
      payload.drop_if_undefined = true;
      try {
        await this.updateStore(payload);
        await this.fetchStoreDetails({ storeId: this.objectId });
        this.setStoreDetail();
        this.hitASnag = false;
      } catch (err) {
        this.alertError(err);
      }
    }
  }
};
</script>
