<template>
  <v-col class="px-2">
    <h4>
      {{ field.name }}
    </h4>

    <VSwatches
      v-if="field.swatch_type === 'vue-swatches'"
      swatches="text-advanced"
      show-fallback
      v-model="(value || field.obj || obj)[field.field]"
    />

    <v-color-picker
      v-else
      flat
      hide-canvas
      hide-mode-switch
      :swatches="swatches"
      show-swatches
      mode="hexa"
      type="hex"
      v-model="(value || field.obj || obj)[field.field]"
      class="remove-max-width"
    />
  </v-col>
</template>

<style>
.v-color-picker.remove-max-width {
  max-width: none !important;
  width: 100%;
}
.v-color-picker__color {
  width: 40px;
}

.vue-swatches__trigger {
  border-style: solid;
  border-width: 2px;
  border-color: lightgrey;
}
</style>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import { InputMixin } from "@/lib/InputMixin.js";
import colors from "@/material-swatch.json";

export default {
  name: "SwatchComponent",
  mixins: [InputMixin],
  components: { VSwatches },
  data() {
    const swatches = [];
    Object.values(colors).forEach((colorSets) => {
      let colorSet = [];
      Object.entries(colorSets).forEach(([k, v]) => {
        if (["lighten", "darken", "accent"].includes(k)) {
          colorSet = colorSet.concat(Object.keys(v));
        } else {
          colorSet.push(k);
        }
      });
      swatches.push(colorSet);
    });
    return {
      swatches
    };
  }
};
</script>
