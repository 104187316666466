<template>
  <v-container>
    <div>
      <div class="alert-container">
        <v-alert
          type="success"
          transition="slide-y-transition"
          dismissible
          outlined
          border="left"
          :value="authSheetSuccess"
        >
          <v-row class="title"> Authorized! It's all yours! </v-row>
          <v-row>
            Copy the route below to token if you need to use this API within
            your application.
          </v-row>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  flat
                  readonly
                  append-icon="fa-copy"
                  @click:append="copyRoute()"
                  label="Generated token route"
                  hint="Copy this add this route to token to use the API within your application"
                  v-model="route"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-alert>
      </div>

      <div class="alert-container">
        <v-alert
          type="error"
          transition="slide-y-transition"
          :value="authSheetHitASnag"
          dismissible
          outlined
          border="left"
        >
          <v-row class="title"> Oops, we hit a snag! </v-row>
          <v-row class="py-3">
            You might want to check again if you shared the sheet following the
            instruction below or delete the meta tab on your sheet and try
            again.
          </v-row>
          <v-row> {{ authSheetError.error }} </v-row>
          <v-row v-if="authSheetError.message">
            Details: {{ authSheetError.message }}
          </v-row>
        </v-alert>
      </div>
    </div>
    <v-card>
      <v-card-title justify-center>Authorize New Google Sheet</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="text--primary body-1">
              <ol>
                <li class="pa-3">
                  Click
                  <v-btn color="green" small dark>Share</v-btn>
                  on the top right corner in google sheet.
                </li>
                <li class="pa-3">
                  Click to copy the following email and add it as an editor to
                  your google sheet:
                  <v-col class="col-10" no-gutters>
                    <v-text-field
                      v-model="waitressRole"
                      label="Click to copy waitress role"
                      dense
                      readonly
                      full-width
                      color="green"
                      :append-icon="waitressRoleCopied"
                      @click="copyWaitressRole()"
                    ></v-text-field>
                  </v-col>
                </li>
                <li class="pa-3">
                  Copy the google sheet URL:
                  <v-form
                    ref="WaitressAutorizeForm"
                    v-model="WaitressAuthorizeFormValid"
                  >
                    <v-row no-gutters class="py-5">
                      <v-col class="col-10" no-gutters>
                        <v-text-field
                          outlined
                          flat
                          label="Google Sheet URL"
                          hint="Copy google sheet url from browser"
                          :rules="[rules.required]"
                          v-model="sheetId"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="no-gutters">
                        <v-btn
                          outlined
                          tile
                          height="56"
                          color="secondary"
                          :disabled="authorizing"
                          @click="authorizeSheet()"
                        >
                          <div v-if="!authorizing">
                            <v-icon>fa-unlock</v-icon>
                          </div>
                          <div v-if="authorizing">
                            <v-icon class="spinner">fa-spinner</v-icon>
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </li>
                <li class="pa-3">
                  Copy route URL and go to
                  <v-btn
                    squared
                    small
                    width="10"
                    height="50"
                    @click="goSettings()"
                  >
                    <v-icon>fa-cog</v-icon>
                  </v-btn>
                  to create access token if you'll be using API in your own
                  application.
                </li>
              </ol>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style>
.spinner {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { mapGetters, mapActions } from "vuex";

Vue.use(VueAxios, axios);

export default {
  name: "WaitressConfigure",
  data() {
    return {
      WaitressAuthorizeFormValid: false,
      waitressRole: "waitress-dev@apio-277201.iam.gserviceaccount.com",
      waitressRoleCopied: "",
      sheetId: "",
      authSheetHitASnag: false,
      authSheetSuccess: false,
      authorizing: false,
      authSheetError: {},
      rules: {
        required: (value) => !!value || "Required."
      }
    };
  },
  computed: {
    ...mapGetters("user", ["user", "getUserAToken", "getUserATokenAuthHeader"]),
    route() {
      return "waitress/gsheets/" + this.extracted;
    },
    extracted() {
      return this.extractSheetId(this.sheetId);
    }
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    goSettings() {
      return this.$router.push("/settings");
    },
    authorizeSheet() {
      this.$refs.WaitressAutorizeForm.validate();
      if (!this.WaitressAuthorizeFormValid) {
        let err = new Error(
          "Please make sure you provide the google sheet url."
        );
        this.alertError(err);
        throw err;
      }

      this.authorizing = true;
      this.auth0GetToken().then(() => {
        this.waitressAuthSheet();
      });
    },

    alertError(error) {
      this.authSheetError = {
        error: error
      };

      this.authSheetHitASnag = true;
      this.$forceUpdate();
    },

    extractSheetId(sheetUrl) {
      sheetUrl = sheetUrl.replace("https://", "");
      sheetUrl = sheetUrl.replace("docs.google.com/spreadsheets/d/", "");

      return sheetUrl.split("/")[0];
    },
    async waitressAuthSheet() {
      let [verify, auth] = await Promise.all([
        this.verifySheetOwner(),
        this.authSheetRequest()
      ]);

      if (!(verify instanceof Error)) {
        if (verify.status == 200 && verify.data.ok === true) {
          this.showAuthSuccess();
          return;
        }
      }
      if (auth instanceof Error) {
        this.showAuthHitASnag(auth);
      } else {
        this.showAuthSuccess();
      }
      return;
    },

    showAuthSuccess() {
      this.authorizing = false;
      this.authSheetSuccess = true;
      this.authSheetHitASnag = false;
      this.$forceUpdate();
    },

    showAuthHitASnag(err) {
      this.authorizing = false;
      this.authSheetError = {
        error: err,
        message: err.response.data.message
      };
      this.authSheetSuccess = false;
      this.authSheetHitASnag = true;
      this.$forceUpdate();
    },

    async verifySheetOwner() {
      let router = `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}`;
      let payload = {
        route: `waitress/gsheets/${this.extracted}`
      };

      try {
        let resp = await axios.post(
          `${router}/verify/route`,
          payload,
          this.getUserATokenAuthHeader
        );
        return resp;
      } catch (err) {
        return err;
      }
    },

    async authSheetRequest() {
      let url = `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/router/waitress/gsheets/auth/${this.extracted}`;
      let headers = {
        headers: {
          Authorization: `bearer ${this.getUserAToken}`
        }
      };
      let payload = { owner: this.user.sub };
      try {
        let resp = await Vue.axios.post(url, payload, headers);
        return resp;
      } catch (err) {
        return err;
      }
    },

    async copyWaitressRole() {
      navigator.clipboard.writeText(this.waitressRole);
      this.waitressRoleCopied = "fa-check";
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.waitressRoleCopied = "";
    },
    copyRoute() {
      navigator.clipboard.writeText(this.route);
    }
  }
};
</script>
