import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { goldfishApiUrl } from "@/lib/utl.js";

Vue.use(VueAxios, axios);

const state = () => ({
  Pages: ["page1", "page2", "page3"],
  NewPageId: "page1",
  PageDetails: {},
  PagesFetched: false
});

const getters = {
  pages: (state) => {
    return state.Pages;
  },
  pagesFetched: (state) => {
    return state.PagesFetched;
  },
  pageDetails: (state) => {
    return state.PageDetails;
  },
  newPageId: (state) => {
    return state.NewPageId;
  }
};
const mutations = {
  updatePages(state, pages) {
    state.Pages = pages;
  },
  updatePage(state, payload) {
    state.PageDetails[payload.pageId] = payload.pageDetail;
  },
  deletePage(state, pageId) {
    let pages = [...state.Pages];
    state.Pages = pages.filter((x) => x.id != pageId);
  },
  setNewPageId(state, pageId) {
    state.NewPageId = pageId;
  }
};
const actions = {
  UpdateNewPageId({ commit }, pageId) {
    commit("setNewPageId", pageId);
  },
  async fetchPages({ commit, state, rootGetters }) {
    try {
      const result = await Vue.axios.get(
        goldfishApiUrl(),
        rootGetters["user/getUserATokenAuthHeader"]
      );
      commit("updatePages", result.data["page-id"]);
      state.PagesFetched = true;
    } catch (err) {
      throw new Error(`Error fetching pages, error: ${err}`);
    }
  },
  async fetchPageDetails({ commit, rootGetters }, payload) {
    let data = {
      headers: {
        Authorization: `bearer ${rootGetters["user/getUserAToken"]}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      data: {}
    };

    try {
      const result = await Vue.axios.get(goldfishApiUrl(payload.pageId), data);
      commit("updatePage", {
        pageId: payload.pageId,
        pageDetail: result.data
      });
    } catch (err) {
      throw new Error(`Error fetching pages, error: ${err}`);
    }
  },
  async updatePage({ rootGetters }, payload) {
    const pageId = payload.id;

    try {
      await Vue.axios.patch(
        goldfishApiUrl(pageId),
        payload,
        rootGetters["user/getUserATokenAuthHeader"]
      );
    } catch (err) {
      throw new Error(
        `Error updating page for page-id ${pageId}, error: ${err}`
      );
    }
  },
  async deletePage({ commit, rootGetters }, pageId) {
    try {
      await Vue.axios.delete(
        goldfishApiUrl(pageId),
        rootGetters["user/getUserATokenAuthHeader"]
      );
      commit("deletePage", pageId);
    } catch (err) {
      throw new Error(
        `Error deleting page for page-id ${pageId}, error: ${err}`
      );
    }
  },
  setNewPage({ commit, payload }) {
    commit("newPageId", payload.pageId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
