import Vue from "vue";
import { WaitressMixin } from "@/lib/WaitressMixin.js";
import { mapGetters, mapActions } from "vuex";

export const ChopinMixin = {
  mixins: [WaitressMixin],
  data() {
    return {
      creatingStore: false,
      storeCreated: false,
      storeId: null,
      storeName: null,
      trampolineService: process.env.VUE_APP_TRAMPOLINE_SERVICE_URL,
      trampolineRouter: process.env.VUE_APP_TRAMPOLINE_ROUTER_URL,
      chopout: process.env.VUE_APP_CHOPOUT_SERVICE_URL,
      API: {
        ChopoutSubmit: {
          name: "Chopout Submit",
          method: "POST",
          aud: process.env.VUE_APP_CHOPOUT_SERVICE_URL,
          field: "chopout_submit_request_auth_token"
        },
        Order: {
          name: "Order",
          method: "POST",
          aud: process.env.VUE_APP_CHOPOUT_SERVICE_URL,
          field: "chopout_order_submit_request_auth_token"
        },
        Catalog: {
          name: "Catalog",
          method: "GET",
          aud: process.env.VUE_APP_CHOPIN_SERVICE_URL,
          field: "catalog_request_auth_token"
        }
      }
    };
  },
  computed: {
    ...mapGetters("user", ["user", "email", "getUserATokenAuthHeader"])
  },
  methods: {
    ...mapActions("user", [
      "addTToken",
      "addTTokenPolicy",
      "fetchTTokenDetail"
    ]),
    ...mapActions("chopin", ["fetchStores", "UpdateNewStoreId"]),
    ...mapActions("postit", ["fetchEmails"]),

    setStoreName(name) {
      this.storeName = name;
    },
    goToConfigure() {
      this.$router.push(`/app/chopin`);
    },
    storeUrl(storeId) {
      let url = `${this.chopout}/${this.user.sub}/${storeId}`;
      return url;
    },
    getNewStorePayload(catalogApiToken, orderSheetApiToken) {
      return {
        store: {
          name: this.storeName,
          owner_info: {
            email: this.email
          },
          catalog_url: `${this.trampolineRouter}/router/waitress/gsheets/${catalogApiToken.id}`,
          catalog_request_auth_token: catalogApiToken.token,
          order_submit_url: `${this.trampolineRouter}/router/waitress/gsheets/${orderSheetApiToken.id}`,
          chopout_order_submit_request_auth_token: orderSheetApiToken.token
        }
      };
    },
    async AddTToken(alias, aud) {
      let token;
      const payload = {
        alias: alias,
        target_audience: aud
      };
      try {
        token = await this.addTToken(payload);
      } catch (err) {
        // this.alertError(err);
        throw new Error(
          `Error creatingStore ttoken with alias ${alias}, error: ${err}`
        );
      }
      return token;
    },
    async AddPolicy(token, route, method) {
      let details;
      try {
        await this.addTTokenPolicy({
          id: token.id,
          route: route,
          method: method
        });
        details = await this.fetchTTokenDetail(token.id);
      } catch (err) {
        // this.alertError(err);
        throw new Error(
          `Error adding route to ttoken policy for ttoken-id ${token.id}, error: ${err}`
        );
      }
      return details;
    },
    getWaitressApiPolicyRoute(url) {
      const sheetId = this.extractSheetId(url);
      return this.waitressApiPolicyRoute(sheetId);
    },
    async CreateWaitressCatalogToken(catalogUrl) {
      const api = this.API["Catalog"];
      const token = await this.AddTToken(
        `[Chopin] [Generated] [${api.name}] ${this.storeName} Token`,
        api.aud
      );
      const details = await this.AddPolicy(
        token,
        this.getWaitressApiPolicyRoute(catalogUrl),
        api.method
      );
      return details;
    },
    async CreateChopoutSubmitOrderToken(orderSheetUrl) {
      const api = this.API["Order"];
      const token = await this.AddTToken(
        `[Chopin] [Generated] [Chopout] ${this.storeName} Token`,
        api.aud
      );
      const detail = await this.AddPolicy(
        token,
        this.getWaitressApiPolicyRoute(orderSheetUrl),
        api.method
      );
      return detail;
    },
    async CreateChopoutSubmitToken(storeId) {
      const api = this.API["ChopoutSubmit"];
      const token = await this.AddTToken(
        `[Chopin] [Generated] [Chopout] ${this.storeName} Token`,
        api.aud
      );
      const detail = await this.AddPolicy(
        token,
        `chopin/store/${storeId}/submit`,
        api.method
      );
      return detail;
    },

    async CreateNewChopinStore(catalogUrl, orderSheetUrl, alertF) {
      this.creatingStore = true;
      const catalog = this.CreateWaitressCatalogToken(catalogUrl);
      const order = this.CreateChopoutSubmitOrderToken(orderSheetUrl);
      let catalogTokenResp, orderSheetTokenResp;
      try {
        [catalogTokenResp, orderSheetTokenResp] = await Promise.all([
          catalog,
          order
        ]);
      } catch (err) {
        this.creatingStore = false;
        alertF(err);
        throw new Error(
          `Error creating catalog and order sheet token for store, error: ${err}`
        );
      }

      const payload = this.getNewStorePayload(
        {
          id: this.extractSheetId(catalogUrl),
          token: catalogTokenResp.jwt
        },
        {
          id: this.extractSheetId(orderSheetUrl),
          token: orderSheetTokenResp.jwt
        }
      );

      let storeId, chopoutSubmitTokenResp;
      try {
        const createStoreResp = await Vue.axios.post(
          `${this.trampolineService}/router/chopin/store`,
          payload,
          this.getUserATokenAuthHeader
        );

        storeId = createStoreResp.data.store.id;
        this.UpdateNewStoreId(storeId);

        // NOTE: to set store-id for links
        this.storeId = storeId;
      } catch (err) {
        this.creatingStore = false;

        alertF(err);
        throw new Error(`Error creating new store, error: ${err}`);
      }

      try {
        chopoutSubmitTokenResp = await this.CreateChopoutSubmitToken(storeId);
      } catch (err) {
        this.creatingStore = false;

        alertF(err);
        throw new Error(
          `Error creating chopout submit token for store id: ${storeId}, error: ${err}`
        );
      }

      try {
        await Vue.axios.patch(
          `${this.trampolineService}/router/chopin/store/${storeId}`,
          {
            chopout_submit_request_auth_token: chopoutSubmitTokenResp.jwt
          },
          this.getUserATokenAuthHeader
        );
        this.creatingStore = false;
        this.storeCreated = true;
        this.fetchStores();
      } catch (err) {
        this.creatingStore = false;

        alertF(err);
        throw new Error(
          `Error patching chopout submit token for store id: ${storeId}, error: ${err}`
        );
      }
    },
    async CreateStore(payload, catalogUrl, orderSheetUrl, alertF) {
      this.setStoreName(payload.name);
      await this.CreateNewChopinStore(catalogUrl, orderSheetUrl, alertF);
    }
  }
};
