<template>
  <v-container>
    <!-- TODO: no we've hit a snag handler -->
    <v-row>
      <v-col class="col-12 col-sm-8">
        You can enable email confirmation with any gmail account. Click
        <v-icon>fa-plus </v-icon> on the right to give us permission to send
        email confirmation on your behalf.
      </v-col>
      <v-col class="col-12 col-sm-4 text-end">
        <v-btn fab color="success" @click="addEmail(callback)">
          <v-icon>fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PostItMixin } from "@/lib/PostItMixin.js";

export default {
  name: "PostItAddEmailFlow",
  props: ["callback"],
  mixins: [PostItMixin]
};
</script>
