import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import GAuth from "vue-google-oauth2";

Vue.use(GAuth, {
  clientId: process.env.VUE_APP_GOOGLE_APP_CLIENT_ID,
  scope: "profile email https://www.googleapis.com/auth/gmail.send",
  prompt: "consent"
});

export const PostItMixin = {
  computed: {
    ...mapGetters("postit", ["emails", "newEmailId", "emailsFetched"])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    ...mapActions("postit", ["fetchEmails", "addWatch", "deleteWatch"]),

    async addEmail(callback) {
      let authCode = await this.$gAuth.getAuthCode();
      await this.addWatch({ token: authCode });
      await this.fetchEmails();
      if (callback) {
        callback();
      }
    }
  }
};
