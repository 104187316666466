<template>
  <v-card @click="goToService()">
    <v-card-title class="headline text-capitalize">
      {{ appNameSanitized }}
      <v-spacer></v-spacer>
      <v-chip small label text-color="white" :color="tags[tag].color">
        {{ tag }}
      </v-chip>
    </v-card-title>
    <v-container>
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-avatar size="100" tile>
            <v-img :src="appDetails.ImageUrl"></v-img>
          </v-avatar>
        </v-col>
        <v-col>{{ appDetails.Description }}</v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "AppTile",
  data() {
    return {
      tags: {
        app: {
          route: "app",
          color: "#48D1CC"
        },
        flow: {
          route: "flow",
          color: "#87CEFA"
        }
      }
    };
  },
  computed: {
    appNameSanitized() {
      if (this.appDetails && this.appDetails.Name) {
        return this.appDetails.Name.replace(/-/g, " ").replace(/_/g, " ");
      }
      return this.appDetails.Name;
    },
    tag() {
      return this.appDetails.Tag;
    }
  },
  props: ["appDetails"],
  methods: {
    getAppRoute() {
      if (["app", "flow"].includes(this.appDetails.Tag)) {
        return this.appDetails.Tag;
      }
    },
    goToService() {
      const route = this.getAppRoute();
      if (route) {
        return this.$router.push(`/${route}/${this.appDetails.Name}`);
      } else {
        this.$router.push("/");
      }
    }
  }
};
</script>
