<template>
  <v-col class="px-1 py-auto">
    <v-row justify="center" align="end">
      <v-btn
        fab
        small
        :color="config[buttonType].color"
        @click="clickFunc(...clickFuncArgs)"
      >
        <v-icon>{{ config[buttonType].icon }}</v-icon>
      </v-btn>
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: "AddOrMinusButton",
  props: ["buttonType", "clickFunc", "clickFuncArgs"],
  data() {
    return {
      config: {
        minus: {
          color: "error",
          icon: "fa-minus"
        },
        plus: {
          color: "secondary",
          icon: "fa-plus"
        }
      }
    };
  }
};
</script>
