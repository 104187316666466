<template>
  <v-container v-if="fetched" :id="objectId">
    <UpdateAlert :hitASnag="hitASnag" :error="errorDetail" />
    <AreYouSures
      :objectId="objectId"
      :updateDialog="updateDialog"
      :deleteDialog="deleteDialog"
    />
    <ExpansionPanels ref="panels" :forms="forms" :obj="page" />
    <SaveAndTrash :confirmFunc="confirm" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ConfigureMixin } from "@/lib/ConfigureMixin.js";
import ExpansionPanels from "@/components/config/shared/ExpansionPanels.vue";
import SaveAndTrash from "@/components/config/shared/SaveAndTrash.vue";

export default {
  name: "GoldfishConfigurePage",
  mixins: [ConfigureMixin],
  components: {
    ExpansionPanels,
    SaveAndTrash
  },
  data() {
    const rules = {
      max250: (v) => v.length <= 250 || "More than 250 chacters",
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 0 || "Required",
      noat: (v) =>
        typeof v === "string"
          ? v.length >= 0 || "Remove @ from the beginning"
          : true
    };
    return {
      page: {},

      updateDialogCallback: this.updatePageFromForm,
      deleteDialogCallback: this.deletePage,

      rules: rules,
      contactMethod: null,
      contactMethodVal: null,
      episodeInputs: {
        title: null,
        img: null,
        description: null
      },
      slideInputs: {
        slide_image: null,
        slide_description: null
      },
      agendaInputs: {
        time: null,
        speaker: null,
        title: null,
        description: null
      },
      catalogInputs: {
        title: null,
        img: null
      }
    };
  },
  async created() {
    await this.fetchPageDetails({
      pageId: this.objectId
    });
    await this.fetchEmails();

    this.setPageDetail();
    this.fetched = true;
  },
  computed: {
    ...mapGetters("user", ["getUserAToken", "email"]),
    ...mapGetters("goldfish", ["pageDetails"]),
    forms() {
      return [
        {
          name: "Page Info and Design",
          expand: true,
          fields: [
            {
              name: "Page Title",
              label: "Page Title",
              type: "v-text-field",
              field: "title"
            },
            {
              name: "Page Subtitle",
              label: "Page Subtitle",
              type: "v-text-field",
              field: "subtitle"
            },
            {
              name: "(Optional) Page Logo URL",
              label: "Page logo",
              field: "logo_url",
              type: "v-text-field"
            },
            {
              name: "Header Background Color",
              field: "background_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            },
            {
              name: "Header Text Color",
              field: "text_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            },
            {
              name: "Header Text Highlight Color",
              field: "highlight_text_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            },
            {
              name: "Banner Background Color",
              field: "contrast_background_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            },
            {
              name: "Banner Text Color",
              field: "contrast_text_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            },
            {
              name: "Banner Text Highlight Color",
              field: "contrast_highlight_text_color",
              obj: this.page.style.header,
              cols: 4,
              type: "swatch",
              swatch_type: "vue-swatches"
            }
          ]
        },
        {
          name: "Contact Information",
          description: `Add social media integration to the page.
          <ul>
          <li>facebook: full url to facebook page or group</li>
          <li>instagram: full instagram url: https://www.instagram.com/<handle> </li>
          <li>line: line url. <a href="https://www.pkstep.com/archives/5261" target="_blank">See instruction.</a></li>
          </ul>`,
          fields: [
            {
              name: "Social Plugins",
              field: "owner_contact",
              type: "add-to-object",
              key: {
                label: "Contact Method",
                hint: "Contact method type",
                options: [
                  "email",
                  "youtube",
                  "facebook",
                  "instagram",
                  "phone",
                  "line"
                ],
                field: "key",
                val: this.contactMethod,
                rules: [this.rules.required]
              },
              val: {
                label: "Contact Information",
                name: "Contact information details",
                field: "value",
                type: "v-text-field",
                val: this.contactMethodVal,
                rules: [this.rules.required]
              }
            }
          ]
        },
        {
          name: "Hero",
          description: "Image on the top section of the page",
          fields: [
            {
              name: "(Optional) URL to the image shown on the top of the page",
              label: "Hero Image URL",
              field: "hero_img",
              obj: this.page.hero,
              type: "v-text-field"
            },
            {
              name: "(Optional) URL to the image shown on the top of the page (in mobile)",
              label: "Hero Image URL (Mobile)",
              field: "hero_img_mobile",
              obj: this.page.hero,
              type: "v-text-field"
            }
          ]
        },
        {
          name: "About and Introductions",
          description:
            "Add details such as brand stories or page contents to the top of the page",
          fields: [
            {
              name: "(Optional) About Section Title",
              label: "About Section Title",
              type: "v-text-field",
              field: "title",
              obj: this.page.about
            },
            {
              name: "(Optional) About Section Subtitle",
              label: "About Section Subtitle",
              type: "v-text-field",
              field: "subtitle",
              obj: this.page.about
            },
            {
              name: "Introduction Sections",
              field: "episodes",
              obj: this.page.about,
              type: "add-to-array",
              val: this.episodeInputs,
              fields: [
                {
                  type: "v-text-field",
                  field: "title",
                  label: "Intro Title",
                  name: "Title of the introduction section"
                },
                {
                  type: "v-text-field",
                  field: "img",
                  label: "Intro Image URL",
                  name: "Image URL of the introduction section",
                  rules: [this.rules.required]
                },
                {
                  type: "v-text-area",
                  field: "description",
                  label: "Intro Description",
                  name: "Description of the introduction section",
                  is_markdown: true
                }
              ]
            }
          ]
        },
        {
          name: "Customer/Product Highlights",
          description:
            "Picture and description to showcase highlights on the page",
          fields: [
            {
              name: "(Optional) About Section Title",
              label: "Highlights Section Title",
              type: "v-text-field",
              field: "title",
              obj: this.page.slides
            },
            {
              name: "Image Slider",
              field: "slides",
              obj: this.page.slides,
              type: "add-to-array",
              val: this.slideInputs,
              fields: [
                {
                  type: "v-text-field",
                  field: "slide_image",
                  label: "Slide Image URL",
                  name: "Image URL of the slide",
                  rules: [this.rules.required]
                },
                {
                  type: "v-text-area",
                  field: "slide_description",
                  label: "Slide Description",
                  name: "Description of the slide",
                  is_markdown: true
                }
              ]
            }
          ]
        },
        {
          name: "(Optional) Timelines or Agenda",
          fields: [
            {
              name: "(Optional) Agenda Section Title",
              label: "Agenda Section Title",
              type: "v-text-field",
              field: "title",
              obj: this.page.timelines
            },
            {
              field: "events",
              obj: this.page.timelines,
              type: "add-to-array",
              val: this.agendaInputs,
              fields: [
                {
                  type: "v-text-field",
                  field: "title",
                  label: "Event Title",
                  hint: "Title of the event",
                  rules: [this.rules.required]
                },
                {
                  type: "v-text-field",
                  field: "time",
                  label: "Event Datetime",
                  hint: "Datetime of the event",
                  cols: 6
                },
                {
                  type: "v-text-field",
                  field: "speaker",
                  label: "Event Speaker",
                  hint: "Speaker name of the event",
                  cols: 6
                },
                {
                  type: "v-text-area",
                  field: "description",
                  label: "Event Description",
                  hint: "Details of the event",
                  rules: [this.rules.required],
                  is_markdown: true
                }
              ]
            }
          ]
        },
        {
          name: "Youtube Video",
          fields: [
            {
              label: "Video Section Title",
              name: "(Optional) Video Section Title",
              type: "v-text-field",
              field: "title",
              obj: this.page.video
            },
            {
              label: "Youtube URL",
              name: "(Optional) Youtube URL copied from Share button",
              type: "v-text-field",
              field: "url",
              obj: this.page.video
            }
          ]
        },
        {
          name: "Categories or Catalog Display",
          description:
            "Image and description tiles to showcase numerous options",
          fields: [
            {
              field: "title",
              name: "(Optional) Categories Section Title",
              label: "Categories Section Title",
              type: "v-text-field",
              obj: this.page.catalog
            },
            {
              field: "thumbs",
              obj: this.page.catalog,
              type: "add-to-array",
              val: this.catalogInputs,
              fields: [
                {
                  type: "v-text-field",
                  field: "img",
                  label: "Thumbnail Image URL",
                  hint: "Image URL of the thumbnail",
                  rules: [this.rules.required]
                },
                {
                  type: "v-text-field",
                  field: "title",
                  label: "Thumbnail Title",
                  hint: "Title of the thumbnail"
                }
              ]
            }
          ]
        },
        {
          name: "Location",
          fields: [
            {
              field: "title",
              name: "(Optional) Location Section Title",
              label: "Location Section Title",
              type: "v-text-field",
              obj: this.page.location
            },
            {
              field: "url",
              name: "(Optional) Google Map Script",
              label: "Google map embeded script",
              type: "v-text-field",
              obj: this.page.location
            }
          ]
        },
        {
          name: "Contact Us",
          description: "Enable fields in contact us section",
          fields: [
            {
              field: "title",
              name: "(Optional) Contact Us Section Title",
              label: "Contact Us Section Title",
              type: "v-text-field",
              obj: this.page.contact
            },
            {
              field: "name",
              name: "Add Name Field",
              label: "Require user to add name in contact us form",
              hint: "This is required if contact us is enabled",
              type: "v-checkbox",
              cols: 4,
              obj: this.page.contact
            },
            {
              field: "email",
              name: "Add Email Field",
              label: "Require user to add email in contact us form",
              hint: "This is required if contact us is enabled",
              type: "v-checkbox",
              cols: 4,
              obj: this.page.contact
            },
            {
              field: "note",
              name: "Add Note Field",
              label: "Allow user to send a note in contact us form",
              type: "v-checkbox",
              cols: 4,
              obj: this.page.contact
            },
            {
              field: "formUrl",
              name: "Contact Us Form Url",
              label: "Contact us form url (via waitress)",
              type: "v-text-field",
              obj: this.page.contact
            },
            {
              field: "token",
              name: "Contact Us Form Token",
              label: "Contact us form request token (via waitress)",
              type: "v-text-field",
              obj: this.page.contact
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions("goldfish", ["fetchPageDetails", "updatePage", "deletePage"]),
    ...mapActions("postit", ["fetchEmails"]),

    colorOnly(color) {
      if (color.constructor === Object) {
        return color.hexa;
      }
      return color;
    },
    goToDoc(url) {
      window.open(url, "_blank");
    },

    setPageDetail() {
      this.page = this.pageDetails[this.objectId];
      if (!this.page.style.header.background_color) {
        this.page.style.header.background_color = "#FCD236";
      }
    },

    confirm(dialogName) {
      switch (dialogName) {
        case "delete":
          this.deleteDialog.dialog = true;
          break;
        case "update":
          this.updateDialog.dialog = true;
          break;
      }
    },

    alertError(error) {
      this.errorDetail = {
        error: error
      };

      document.getElementById(this.objectId).scrollIntoView();
      this.hitASnag = true;
      this.$forceUpdate();
    },

    validateInput() {
      Object.entries(this.$refs).forEach(([k, v]) => {
        if (!(k.startsWith("PatchGoldfishPageForm") ? v[0].validate() : true)) {
          const splitted = k.split("-");
          const i = splitted[splitted.length - 1];
          this.panel.push(parseInt(i));
        }
      });
    },

    async updatePageFromForm() {
      const valid = Object.entries(this.$refs).every(([k, v]) => {
        return k.startsWith("PatchGoldfishPageForm") ? v[0].validate() : true;
      });
      if (!valid) {
        let err = new Error("Please check your input for page creation.");
        this.alertError(err);
        return;
      }
      const payload = JSON.parse(JSON.stringify(this.page));
      payload.id = this.objectId;

      const youtubeUrl = payload.video.url;

      if (youtubeUrl) {
        if (!youtubeUrl.includes("https://www.youtube.com/embed")) {
          const splitted = youtubeUrl.split("/");
          const videoId = splitted[splitted.length - 1];
          payload.video.url = `https://www.youtube.com/embed/${videoId}`;
        }
      }

      const googleMapScript = payload.location.url;
      if (googleMapScript) {
        const parsed = new DOMParser()
          .parseFromString(googleMapScript, "text/html")
          .getElementsByTagName("iframe");

        if (parsed.length > 0 && parsed[0].src !== null) {
          payload.location.url = parsed[0].src;
        }
      }

      await this.updatePage(payload);
      await this.fetchPageDetails({ pageId: this.objectId });
      this.setPageDetail();

      this.hitASnag = false;
      this.$forceUpdate();
    }
  }
};
</script>
