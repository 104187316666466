<template>
  <v-container>
    <v-btn
      fab
      color="info"
      tile
      absolute
      height="57"
      width="150"
      :style="{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }"
      @click="unblurred()"
      v-if="!getTTokensFetched"
    >
      {{ btnText }}
    </v-btn>
    <v-container fill-width>
      <v-row
        align="center"
        justify="center"
        class="text-center title text-capitalize"
      >
        <v-col class="col-12 col-sm-3"> Alias </v-col>
        <v-col> Token ID </v-col>
      </v-row>
    </v-container>
    <!-- TODO: no error handling when update policy failed -->
    <!-- TODO: no new badge when create policy -->
    <v-container
      v-bind:class="{ blurred: !getTTokensFetched }"
      fluid
      full-width
    >
      <v-row>
        <v-card
          outlined
          flat
          class="border-0"
          width="100%"
          :disabled="!getTTokensFetched"
        >
          <v-expansion-panels>
            <v-expansion-panel v-for="ttoken in getTTokens" :key="ttoken.id">
              <v-expansion-panel-header>
                <v-row class="text-center">
                  <v-col class="col-12 col-sm-3">
                    {{ ttoken.alias }}
                  </v-col>
                  <v-col class="text-center">
                    {{ ttoken.id }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <UserTokenDetailCard :tokenId="ttoken.id" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <v-row class="text-center title" justify="center">
                  Add New Token
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <NewUserTokenCard />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-row>
    </v-container>
  </v-container>
</template>

<style>
.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}
</style>

<script>
import UserTokenDetailCard from "@/components/user/UserTokenDetailCard.vue";
import NewUserTokenCard from "@/components/user/NewUserTokenCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserAuthToken",
  components: { UserTokenDetailCard, NewUserTokenCard },
  data() {
    return {
      btnText: "View Token"
    };
  },
  computed: {
    ...mapGetters("user", [
      "user",
      "getUserAToken",
      "getTTokens",
      "getTTokensFetched"
    ])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken", "fetchTTokens"]),
    async unblurred() {
      await this.auth0GetToken();
      await this.fetchTTokens();
    }
  }
};
</script>
