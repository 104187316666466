<template>
  <v-container class="pa-0">
    <v-form :ref="refName" v-model="field_form_valid" style="width: 100%">
      <v-col class="px-0">
        <h4 class="pb-2">
          {{ field.name }}
        </h4>
      </v-col>
      <v-row
        v-for="(el, key) in (field.obj || obj)[field.field]"
        :key="key"
        width="100%"
      >
        <v-col class="pa-1 py-0" cols="11">
          <template v-if="isArrayForm">
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="ff.cols"
              v-for="(ff, ffi) in field.fields"
              :key="ffi"
            >
              <template v-if="isUserInputsForm">
                <UserInputs
                  formSection="top"
                  :field="ff"
                  :obj="obj"
                  :ff="ff"
                  :el="el"
                />
              </template>
              <template v-else>
                <Combobox
                  v-if="ff.type === 'v-combobox'"
                  :field="ff"
                  :readonly="true"
                  :obj="getEl(ff, el)"
                />
                <ComboboxWithLoad
                  v-if="ff.type === 'v-combobox-with-load'"
                  :field="ff"
                  :readonly="true"
                  :obj="getEl(ff, el)"
                />
                <Select
                  v-if="ff.type === 'v-select'"
                  :field="ff"
                  :obj="getEl(ff, el)"
                />
                <Textfield
                  v-if="ff.type === 'v-text-field'"
                  :field="ff"
                  :obj="getEl(ff, el)"
                />
                <Textarea
                  v-if="ff.type === 'v-text-area'"
                  :field="ff"
                  :obj="getEl(ff, el)"
                />
              </template>
            </v-col>
          </template>
          <template v-else-if="isObjectForm">
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="field.cols || defaultKeyCols"
              width="100%"
            >
              <Textfield
                :field="field.key"
                :value="kvTransformer('key', key)"
                :readonly="true"
              />
            </v-col>
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="field.cols ? 12 - field.cols : 12 - defaultKeyCols"
            >
              <Textarea
                v-if="field.val.type === 'v-textarea'"
                :field="field.val"
                :obj="(field.obj || obj)[field.field]"
                :fieldName="key"
              />
              <Textfield
                v-if="field.val.type === 'v-text-field'"
                :field="field.val"
                :obj="(field.obj || obj)[field.field]"
                :readonly="field.val.readonly"
                :optionsConfig="field.val.optionsConfig"
                :fieldName="key"
              />
            </v-col>
          </template>
        </v-col>
        <AddOrMinusButton
          buttonType="minus"
          :clickFunc="getRemoveFunc()"
          :clickFuncArgs="[key]"
        />
      </v-row>
    </v-form>
    <v-form
      :ref="`add-${refName}`"
      v-model="input_form_valid"
      style="width: 100%"
    >
      <v-row width="100%" class="py-1">
        <v-col class="px-1 py-0" cols="11">
          <template v-if="isArrayForm">
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="ff.cols || 12"
              v-for="(ff, ffi) in field.fields"
              :key="ffi"
            >
              <template v-if="isUserInputsForm">
                <UserInputs :field="field" :obj="obj" :ff="ff" />
              </template>
              <template v-else>
                <Select
                  v-if="ff.type === 'v-select'"
                  :field="ff"
                  :obj="field.obj"
                  :value="field.val"
                />
                <Combobox
                  v-if="ff.type === 'v-combobox'"
                  :field="ff"
                  :obj="field.obj"
                  :value="field.val"
                />
                <ComboboxWithLoad
                  v-if="ff.type === 'v-combobox-with-load'"
                  :field="ff"
                  :obj="field.obj"
                  :value="field.val"
                />
                <Textfield
                  v-if="ff.type === 'v-text-field'"
                  :field="ff"
                  :obj="field.obj"
                  :value="field.val"
                />
                <Textarea
                  v-if="ff.type === 'v-text-area'"
                  :field="ff"
                  :obj="field.obj"
                  :value="field.val"
                />
              </template>
            </v-col>
          </template>
          <template v-else-if="isObjectForm">
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="field.cols || defaultKeyCols"
              width="100%"
            >
              <Select v-if="field.key.options" :field="field.key" :obj="kv" />
              <Textfield v-else :field="field.key" :obj="kv" />
            </v-col>
            <v-col
              class="pa-1 py-0 d-inline-block"
              :cols="field.cols ? 12 - field.cols : 12 - defaultKeyCols"
            >
              <Textarea
                v-if="field.val.type === 'v-textarea'"
                :field="field.val"
                :obj="kv"
              />
              <Textfield
                v-if="field.val.type === 'v-text-field'"
                :field="field.val"
                :obj="kv"
                :readonly="false"
                :optionsConfig="field.val.optionsConfig"
                :optionName="kv.key"
              />
            </v-col>
          </template>
        </v-col>
        <AddOrMinusButton
          buttonType="plus"
          :clickFunc="getAddFunc()"
          :clickFuncArgs="clickToAddFuncArgs"
        />
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";
import AddOrMinusButton from "@/components/config/form/AddOrMinusButton.vue";
import Combobox from "@/components/config/form/Combobox.vue";
import ComboboxWithLoad from "@/components/config/form/ComboboxWithLoad.vue";
import UserInputs from "@/components/config/chopin/UserInputs.vue";
import Select from "@/components/config/form/Select.vue";
import Textfield from "@/components/config/form/Textfield.vue";
import Textarea from "@/components/config/form/Textarea.vue";

export default {
  name: "CompoundFormInputFields",
  mixins: [InputMixin],
  props: ["clickToAddFunc", "clickToAddFuncArgs"],
  components: {
    AddOrMinusButton,
    Combobox,
    ComboboxWithLoad,
    Select,
    Textfield,
    Textarea,
    UserInputs
  },
  data() {
    return {
      field_form_valid: true,
      input_form_valid: true,
      kv: {},
      defaultKeyCols: 3
    };
  },
  methods: {
    getEl(field, el) {
      if (field.getElFunc) {
        return field.getElFunc(el);
      }
      return el;
    }
  }
};
</script>
