const appendIdToBaseIfId = (base, id) => {
  if (id) {
    return `${base}/${id}`;
  }
  return base;
};

const chopinApiUrl = (id) =>
  appendIdToBaseIfId(
    `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/router/chopin/store`,
    id
  );

const trampolineTokenApiUrl = (id) =>
  appendIdToBaseIfId(
    `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/user/token`,
    id
  );
const trampolineTokenPolicyApiUrl = (id) => {
  return appendIdToBaseIfId(
    `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/policy/user/token`,
    id
  );
};

const goldfishApiUrl = (id) =>
  appendIdToBaseIfId(
    `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/router/goldfish/about/`,
    id
  );

const postItApiUrl = `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/router/post-it/gmail/watch`;

export {
  appendIdToBaseIfId,
  postItApiUrl,
  chopinApiUrl,
  goldfishApiUrl,
  trampolineTokenApiUrl,
  trampolineTokenPolicyApiUrl
};
