<template>
  <v-container float class="ml-0" style="z-index: 11; width: 80%">
    <v-app-bar-nav-icon
      style="z-index: 3"
      large
      @click.stop="drawer = !drawer"
    />

    <v-navigation-drawer
      v-model="drawer"
      app
      left
      mini-variant-width="65"
      :mini-variant="minify"
    >
      <v-container class="pa-0">
        <v-list class="py-0">
          <v-list-item class="primary accent-4">
            <v-list-item-avatar height="40" class="px-auto">
              <v-img :src="require('../assets/images/telescope.png')" />
            </v-list-item-avatar>
            <v-list-item-content class="text-h6">
              Telescope
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAuthenticated">
            <v-list-item-avatar>
              <img :src="user.picture" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              <v-list-item-action class="mx-0">
                <v-btn outlined small color="error" @click="auth0Logout()">
                  Sign Out
                </v-btn>
              </v-list-item-action>
            </v-list-item-content>
          </v-list-item>
          <!-- user login -->
          <v-list-item
            v-for="item in items.filter(
              (i) => i.title === 'Login' && !isAuthenticated
            )"
            :key="item.title"
            :to="item.link"
            @click="auth0Login()"
          >
            <v-list-item-icon class="px-auto">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item-group color="primary" class="pa-0 ma-0">
            <!-- control -->
            <v-list-item
              v-for="item in items.filter((i) => !i.user)"
              :key="item.title"
              :to="item.link"
            >
              <v-list-item-icon class="px-auto">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>

            <!-- user -->
            <v-list-item
              v-for="item in items.filter(
                (i) => i.title === 'Setting' && isAuthenticated
              )"
              :key="item.title"
              :to="item.link"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>

            <!-- user logout -->
            <v-list-item
              v-for="item in items.filter(
                (i) => i.title === 'Logout' && isAuthenticated
              )"
              :key="item.title"
              :to="item.link"
              inactive
              @click="auth0Logout()"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>
      <template v-slot:append>
        <v-list>
          <!-- expand -->
          <v-list-item @click="expandOrMinifySidebar()">
            <v-icon v-if="!minify"> fas fa-chevron-left </v-icon>
            <v-icon v-else> fas fa-chevron-right </v-icon>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "sidebar",
  data: () => ({
    items: [
      { title: "Applications", icon: "fa fa-home", link: "/" },
      // { title: "Apps", icon: "fa-th", link: "/apps" },
      {
        title: "Login",
        icon: "fa-sign-in-alt",
        link: "/",
        user: true
      },
      { title: "Setting", icon: "fa-cog", link: "/settings", user: true }
    ],
    minify: false,
    drawer: null
  }),
  computed: {
    ...mapGetters("user", ["user", "isAuthenticated"])
  },
  methods: {
    ...mapActions("user", ["auth0Login", "auth0Logout"]),
    goHome() {
      return this.$router.push("/");
    },
    expandOrMinifySidebar() {
      this.minify = !this.minify;
    }
  }
};
</script>
