<template>
  <div>
    <!-- eslint-disable-next-line -->
    <v-dialog v-model="value.dialog" width="500">
      <v-card>
        <v-card-title primary-title class="headline justify-center">
          <div class="pa-5">Are You Sure?</div>
        </v-card-title>
        <v-card-actions class="text-center">
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-btn fab color="error" @click="clickNo()">
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn fab color="secondary" @click="clickYes()">
                  <v-icon>fa-check</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AreYouSure",
  props: ["value", "id"],
  methods: {
    async clickYes() {
      try {
        await this.value.callback(this.id);
      } finally {
        // eslint-disable-next-line
        this.value.dialog = false;
      }
    },
    clickNo() {
      // eslint-disable-next-line
      this.value.dialog = false;
    }
  }
};
</script>
