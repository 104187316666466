<template>
  <User />
</template>

<script>
import User from "@/components/user/Settings.vue";

export default {
  name: "SettingsView",
  props: {
    source: String
  },
  components: {
    User
  }
};
</script>
