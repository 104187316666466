<template>
  <v-container>
    <v-breadcrumbs
      class="pl-0 text-capitalize"
      :items="breadcrumbs"
      divider="/"
    />
    <v-row justify="center" align="center">
      <v-col cols="6" sm="2">
        <v-avatar size="100%" tile>
          <v-img :src="details.ImageUrl || ''"> </v-img>
        </v-avatar>
      </v-col>
      <v-col>
        <p class="headline text-capitalize mb-0">
          {{ details ? details.Name : "" }}
        </p>
        <p class="text-subtitle-1">
          {{ details ? details.Description : "" }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="secondary"
        grow
      >
        <v-tab v-for="item in items" :key="item.name" :to="item.route">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row>
      <router-view></router-view>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  data() {
    return {
      tab: null
    };
  },
  computed: {
    app() {
      return this.$route.params.name;
    },
    breadcrumbs() {
      return [
        {
          text: "Home",
          to: {
            path: "/",
            name: "Explore"
          }
        },
        {
          text: this.app + " App",
          to: {
            name: "Explore"
          }
        }
      ];
    },
    items() {
      return [
        {
          name: "Configuration",
          route: `/app/${this.app}`
        },
        {
          name: "About",
          route: `/app/${this.app}/about`
        },
        {
          name: "Documentation",
          route: `/app/${this.app}/swagger`
        }
      ];
    },
    details() {
      return this.getDetails()(this.app);
    }
  },
  async created() {
    await this.fetchApps();
    this.$forceUpdate();
  },
  methods: {
    ...mapGetters("apps", ["getDetails"]),
    ...mapActions("apps", ["fetchApps"])
  }
};
</script>
