<template>
  <v-row class="text-center pt-3" justify="center">
    <v-col>
      <v-btn fab color="error" @click="confirmFunc('delete')">
        <v-icon>fa-trash</v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <v-btn fab color="secondary" @click="confirmFunc('update')">
        <v-icon>fa-save</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SaveAndTrash",
  props: ["confirmFunc"]
};
</script>
