import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  trampolineTokenApiUrl,
  trampolineTokenPolicyApiUrl
} from "@/lib/utl.js";

Vue.use(VueAxios, axios);

const auth0 = new Auth0Client({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  client_id: process.env.VUE_APP_AUTH0_CLIENT_ID
});

const state = () => ({
  User: {},
  UserAToken: null,
  UserATokenUpdated: null,
  TTokensFetched: false,
  TTokens: [
    {
      id: "08ecf5fb-c294-4bbe-ae07-ac2041b4e744",
      alias: "token 1"
    },
    {
      id: "dadcf43d-e3e9-4d40-9c8f-feaf5775e8e6",
      alias: "token 2"
    },
    {
      id: "7a991751-0ba7-4b28-8657-fab7b0bf9454",
      alias: "token 3"
    }
  ],
  UserOpenId: null,
  TTokenDetail: {},
  TTokenPolicy: {}
});

const getters = {
  user: (state) => {
    return state.User;
  },
  email: (state) => {
    return state.User.email;
  },
  userId: (state) => {
    return state.UserOpenId;
  },
  isAuthenticated: (state) => {
    let user = state.User;
    let auth = !(Object.keys(user).length === 0 && user.constructor === Object);
    return auth;
  },
  getUserATokenUpdated: (state) => {
    return state.UserATokenUpdated;
  },
  getUserAToken: (state) => {
    return state.UserAToken;
  },
  getUserATokenAuthHeader: (state) => {
    return {
      headers: {
        Authorization: `bearer ${state.UserAToken}`
      }
    };
  },
  getTTokensFetched: (state) => {
    return state.TTokensFetched;
  },
  getTTokens: (state) => {
    return state.TTokens;
  },
  tTokenDetail: (state) => {
    return state.TTokenDetail;
  },
  tTokenPolicy: (state) => {
    return state.TTokenPolicy;
  }
};

const mutations = {
  resetATokenState(state) {
    state.UserAToken = null;
    state.UserATokenUpdated = null;
  },
  resetTTokensState(state) {
    state.TTokensFetched = false;
    state.TTokens = [
      {
        id: "08ecf5fb-c294-4bbe-ae07-ac2041b4e744",
        alias: "token 1"
      },
      {
        id: "dadcf43d-e3e9-4d40-9c8f-feaf5775e8e6",
        alias: "token 2"
      },
      {
        id: "7a991751-0ba7-4b28-8657-fab7b0bf9454",
        alias: "token 3"
      }
    ];
    state.TTokenDetail = {};
    state.TTokenPolicy = {};
  },
  updateUser(state, user) {
    state.User = user;
    state.UserOpenId = user.sub;
  },
  updateToken(state, token) {
    state.UserAToken = token;
    state.UserATokenUpdated = Date.now();
  },
  updateTTokens(state, ttokens) {
    state.TTokens = ttokens;
    state.TTokensFetched = true;
  },
  addTTokenDetail(state, payload) {
    state.TTokenDetail[payload.id] = payload.detail;
  },
  addTTokenPolicy(state, payload) {
    state.TTokenPolicy[payload.id] = payload.policy;
  },
  deleteToken(state, id) {
    let tokens = [...state.TTokens];
    state.TTokens = tokens.filter((ttoken) => ttoken.id != id);
  }
};
const actions = {
  resetTTokensState({ commit }) {
    commit("resetTTokensState");
  },
  async auth0Login({ commit }) {
    await auth0.loginWithPopup();
    let user = await auth0.getUser();
    commit("updateUser", user);
  },
  auth0Logout({ commit }) {
    auth0.logout();
    commit("updateUser", {});
    commit("resetATokenState", {});
    commit("resetTTokensState", {});
  },

  async auth0GetTokenOnly({ commit, getters }) {
    // NOTE: when running locally
    const aud = { audience: "https://dev.trampoline.apiobuild.com" };
    // const aud = { audience: process.env.VUE_APP_TRAMPOLINE_SERVICE_URL };
    if (getters.getUserAToken && getters.getUserATokenUpdated) {
      const milliseconds = Date.now() - getters.getUserATokenUpdated;
      // NOTE: last requested within 30 mins
      if (Math.floor(milliseconds / 1000 / 60) <= 30) {
        return;
      }
    }

    let accessToken;
    try {
      accessToken = await auth0.getTokenSilently(aud);
    } catch (err) {
      if (err.error === "login_required") {
        // NOTE: ask to login if required
        accessToken = await auth0.getTokenWithPopup(aud);
      }
    }
    commit("updateToken", accessToken);
  },

  async auth0GetToken({ getters, dispatch }) {
    if (!getters.isAuthenticated) {
      await dispatch("auth0Login");
    }
    await dispatch("auth0GetTokenOnly");
  },

  async fetchTTokens({ commit, getters }) {
    try {
      let result = await Vue.axios.get(
        trampolineTokenApiUrl(),
        getters.getUserATokenAuthHeader
      );
      commit("updateTTokens", result.data["ids"]);
    } catch (err) {
      throw new Error(`Error fetching ttokens, error: ${err}`);
    }
  },
  async deleteTTokenId({ commit, getters }, id) {
    try {
      await Vue.axios.delete(
        trampolineTokenApiUrl(id),
        getters.getUserATokenAuthHeader
      );
      commit("deleteToken", id);
      return;
    } catch (err) {
      throw new Error(
        `Error deleting ttoken detail for token-id ${id}, error: ${err}`
      );
    }
  },
  async addTToken({ getters }, payload) {
    try {
      let result = await Vue.axios.post(
        trampolineTokenApiUrl(),
        payload,
        getters.getUserATokenAuthHeader
      );
      return result.data;
    } catch (err) {
      throw new Error(`Error adding ttoken, error: ${err}`);
    }
  },
  async fetchTTokenDetail({ commit, getters }, id) {
    try {
      let result = await Vue.axios.get(
        trampolineTokenApiUrl(id),
        getters.getUserATokenAuthHeader
      );
      let payload = {
        id: id,
        detail: result.data
      };
      commit("addTTokenDetail", payload);
      return result.data;
    } catch (err) {
      throw new Error(
        `Error fetching ttoken detail for token-id ${id}, error: ${err}`
      );
    }
  },
  async updateTTokenAud({ getters }, payload) {
    try {
      await Vue.axios.patch(
        trampolineTokenApiUrl(payload.id),
        { target_audience: payload.aud },
        getters.getUserATokenAuthHeader
      );
    } catch (err) {
      throw new Error(
        `Error fetching ttoken detail for token-id ${payload.id}, error: ${err}`
      );
    }
  },
  async fetchTTokenPolicy({ commit, getters }, id) {
    try {
      let result = await Vue.axios.get(
        trampolineTokenPolicyApiUrl(id),
        getters.getUserATokenAuthHeader
      );
      let payload = {
        id: id,
        policy: result.data
      };
      commit("addTTokenPolicy", payload);
    } catch (err) {
      throw new Error(
        `Error fetching ttoken policy for token-id ${id}, error: ${err}`
      );
    }
  },
  async addTTokenPolicy({ getters }, payload) {
    try {
      await Vue.axios.post(
        trampolineTokenPolicyApiUrl(payload.id),
        {
          route: payload.route,
          method: payload.method
        },
        getters.getUserATokenAuthHeader
      );
    } catch (err) {
      throw new Error(
        `Error add ttoken policy for token-id ${payload.id}, error: ${err}`
      );
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
