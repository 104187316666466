<template>
  <v-container>
    <v-breadcrumbs
      class="pl-0 text-capitalize"
      :items="breadcrumbs"
      divider="/"
    />
    <v-row justify="center" align="center">
      <v-col cols="6" sm="2">
        <v-avatar size="100%">
          <img :src="user.picture" />
        </v-avatar>
      </v-col>
      <v-col>
        <p class="text-h5 mb-0">{{ user.name }}</p>
        <p class="text-subtitle-1">{{ user.email }}</p>
        <v-btn outlined color="error" @click="auth0Logout()"> Sign Out </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="secondary"
        grow
      >
        <v-tab v-for="item in items" :key="item.name" :to="item.route">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SettingsComponent",
  data() {
    return {
      tab: null,
      breadcrumbs: [
        {
          text: "Home",
          to: {
            path: "/",
            name: "Explore"
          }
        },
        {
          text: "Settings",
          disabled: true
        }
      ],
      items: [
        {
          name: "Manage Token",
          route: `/settings`
        },
        {
          name: "Billing",
          route: `/settings/billing`
        }
      ]
    };
  },
  created() {
    this.resetTTokensState();
  },
  computed: {
    ...mapGetters("user", ["user", "token"])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken", "auth0Logout", "resetTTokensState"])
  }
};
</script>
