<template>
  <ConfigMain
    configName="Goldfish"
    :createNewFunc="createNewPage"
    :newId="newPageId"
    :objects="pages"
    :fetched="pagesFetched"
    :unblurredFunc="unblurred"
    :getObjectId="getPageId"
    :getObjectName="getPageName"
    :getObjectUrl="pageUrl"
  />
</template>

<script>
import ConfigMain from "@/components/config/ConfigMain.vue";
import { GoldfishMixin } from "@/lib/GoldfishMixin.js";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GoldfishConfigure",
  components: { ConfigMain },
  mixins: [GoldfishMixin],
  data() {
    return {
      blurred: true
    };
  },
  computed: {
    ...mapGetters("goldfish", [
      "pages",
      "pageDetails",
      "pagesFetched",
      "newPageId"
    ])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    ...mapActions("goldfish", ["UpdateNewPageId", "fetchPages"]),
    getPageId(page) {
      return page.id;
    },
    getPageName(page) {
      return page.alias;
    },
    async createNewPage() {
      const resp = await this.CreateNewPage();
      this.UpdateNewPageId(resp.data.pageId);
      await this.fetchPages();
    },
    async unblurred() {
      await this.auth0GetToken();
      await this.fetchPages();
    }
  }
};
</script>
