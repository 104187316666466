import AreYouSures from "@/components/config/shared/AreYouSures.vue";
import UpdateAlert from "@/components/config/shared/UpdateAlert.vue";
import Textfield from "@/components/config/form/Textfield.vue";
import Textarea from "@/components/config/form/Textarea.vue";
import Swatch from "@/components/config/form/Swatch.vue";
import Checkbox from "@/components/config/form/Checkbox.vue";
import Combobox from "@/components/config/form/Combobox.vue";
import ComboboxWithLoad from "@/components/config/form/ComboboxWithLoad.vue";
import Select from "@/components/config/form/Select.vue";
import NumberDial from "@/components/config/form/NumberDial.vue";
import CompoundForm from "@/components/config/form/CompoundForm.vue";

export const ConfigureMixin = {
  name: "ConfigureMixin",
  components: {
    AreYouSures,
    UpdateAlert,
    Textfield,
    Textarea,
    NumberDial,
    Swatch,
    Checkbox,
    Combobox,
    ComboboxWithLoad,
    Select,
    CompoundForm
  },
  props: ["objectId"],
  created() {
    this.updateDialog.callback = this.updateDialogCallback;
    this.deleteDialog.callback = this.deleteDialogCallback;
  },
  data() {
    return {
      panel: [0],
      formName: "PatchConfigureForm",
      valid: true,
      fetched: false,
      hitASnag: false,
      errorDetail: {},
      updateDialog: {
        dialog: false,
        yes: null,
        callback: this.updateDialogCallback
      },
      deleteDialog: {
        dialog: false,
        yes: null,
        callback: this.deleteDialogCallback
      }
    };
  },
  methods: {
    validateInput() {
      const flatten = (refs) => {
        let children = [];
        Object.entries(refs).forEach(([k, v]) => {
          if (Array.isArray(v)) {
            v.forEach((x) => {
              children.push([k, x]);
            });
          } else {
            children.push([k, v]);
          }
        });
        return children;
      };
      let children = flatten(this.$refs);
      let allValid = true;

      while (children.length > 0) {
        const [nodeName, node] = children.pop();
        if (nodeName.startsWith(this.formName)) {
          if (node.validate !== undefined && node.validate() !== true) {
            allValid = false;
            // NOTE: keep panel open
            const splitted = nodeName.split("-");
            const i = splitted[splitted.length - 1];
            this.panel.push(parseInt(i));
          }
        }
        children = children.concat(flatten(node.$refs));
      }
      return allValid;
    },

    confirm(dialogName) {
      switch (dialogName) {
        case "delete":
          this.deleteDialog.dialog = true;
          break;
        case "update":
          this.updateDialog.dialog = true;
          break;
      }
    },
    alertError(error) {
      this.errorDetail = error.message;

      document.getElementById(this.objectId).scrollIntoView();
      this.hitASnag = true;
    }
  }
};
