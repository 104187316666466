<template>
  <v-container>
    <v-container>
      <v-row class="px-5">
        <!-- Search Bar -->
        <v-toolbar flat>
          <v-text-field
            hide-details
            prepend-icon="fa-search"
            single-line
          ></v-text-field>
        </v-toolbar>
      </v-row>
    </v-container>
    <!-- Grid -->
    <AppTiles />
  </v-container>
</template>

<script>
import AppTiles from "@/components/AppTiles.vue";

export default {
  name: "ExploreComponent",
  components: { AppTiles }
};
</script>
