<template>
  <div>
    <v-container v-if="detailFetched">
      <div>
        <AreYouSure :value="updateDialog" :id="detail.id" />
        <AreYouSure :value="deleteDialog" :id="detail.id" />
      </div>
      <v-row>
        <v-text-field outlined flat label="Target Audience" v-model="aud">
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          append-icon="fa-copy"
          type="password"
          filled
          flat
          label="Service Token"
          :value="detail.jwt"
          @click:append="copyTokenToClipboard()"
        >
        </v-text-field>
      </v-row>
    </v-container>
    <v-container v-if="policyFetched">
      <v-row class="title text-center" justify="center" fluid fill-width>
        Access Policy
      </v-row>
      <v-row v-for="(access, route) in policy.access" :key="route">
        <v-col class="col-12 col-sm-9">
          {{ route }}
        </v-col>
        <v-col class="col-12 col-sm-3">
          {{ access }}
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="col-12 col-sm-8">
          <v-text-field
            outlined
            flat
            hint="Route to whitelist."
            label="Route"
            :rules="[rules.required]"
            v-model="route"
          >
          </v-text-field>
        </v-col>
        <v-col class="col-12 col-sm-2">
          <v-overflow-btn
            :items="methods"
            outlined
            label="method"
            item-value="text"
            class="text-center"
            v-model="method"
          ></v-overflow-btn>
        </v-col>
        <v-col>
          <v-btn fab color="secondary" @click="addTTokenPolicyFromForm()">
            <v-icon>fa-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="detailFetched">
      <v-row>
        <v-col class="col-5 text-right" style="font-size: small">
          Created
        </v-col>
        <v-col style="font-size: small">
          {{ created }}
        </v-col>
      </v-row>
      <v-row class="text-center" justify="center">
        <v-col>
          <v-btn fab color="error" @click="confirm('delete')">
            <v-icon>fa-trash</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn fab color="secondary" @click="confirm('update')">
            <v-icon>fa-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AreYouSure from "@/components/AreYouSure.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserTokenDetailCard",
  components: {
    AreYouSure
  },
  props: ["tokenId"],
  data() {
    return {
      aud: "",
      route: "",
      method: "GET",
      methods: [
        { text: "GET" },
        { text: "POST" },
        { text: "PATCH" },
        { text: "DELETE" }
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 0 || "Required"
      },
      policyFetched: false,
      detailFetched: false,
      updateDialog: {
        dialog: false,
        yes: null,
        callback: this.updateTTokenAudFromForm
      },
      deleteDialog: {
        dialog: false,
        yes: null,
        callback: this.deleteTTokenId
      }
    };
  },
  created() {
    this.fetchTTokenDetail(this.tokenId).then(() => {
      this.setTokenDetail();
      this.$forceUpdate();
      this.detailFetched = true;
    });
    this.fetchTTokenPolicy(this.tokenId).then(() => {
      this.setTokenPolicy();
      this.$forceUpdate();
      this.policyFetched = true;
    });
  },
  computed: {
    ...mapGetters("user", ["tTokenDetail", "tTokenPolicy"]),
    created() {
      return this.detail.created;
    }
  },
  methods: {
    ...mapActions("user", [
      "fetchTTokenDetail",
      "fetchTTokenPolicy",
      "deleteTTokenId",
      "addTTokenPolicy",
      "updateTTokenAud"
    ]),
    copyTokenToClipboard() {
      navigator.clipboard.writeText(this.detail.jwt);
    },
    setTokenDetail() {
      this.detail = this.tTokenDetail[this.tokenId];
      this.aud = this.detail.target_audience;
    },
    setTokenPolicy() {
      this.policy = this.tTokenPolicy[this.tokenId];
    },
    addTTokenPolicyFromForm() {
      let payload = {
        id: this.tokenId,
        route: this.route,
        method: this.method
      };
      this.addTTokenPolicy(payload).then(() => {
        this.fetchTTokenPolicy(this.tokenId).then(() => {
          this.setTokenPolicy();
          this.$forceUpdate();
        });
        this.resetPolicyFields();
      });
    },
    confirm(dialogName) {
      switch (dialogName) {
        case "delete":
          this.deleteDialog.dialog = true;
          break;
        case "update":
          this.updateDialog.dialog = true;
          break;
      }
    },
    updateTTokenAudFromForm() {
      let payload = {
        id: this.tokenId,
        aud: this.aud
      };
      this.updateTTokenAud(payload).then(() => {
        this.fetchTTokenDetail(this.tokenId).then(() => {
          this.setTokenDetail();
          this.$forceUpdate();
        });
      });
    },
    resetPolicyFields() {
      (this.route = ""), (this.method = "GET");
    }
  }
};
</script>
