<template>
  <v-container>
    <component :is="componentToLoad"> </component>
  </v-container>
</template>

<script>
import ChopinStoreFlow from "@/components/flow/ChopinStores.vue";

export default {
  name: "CreateFlow",
  components: {
    ChopinStoreFlow
  },
  data() {
    return {
      content: ""
    };
  },
  computed: {
    app() {
      return this.$route.params.name;
    },
    componentToLoad() {
      let contents = {
        "chopin-stores": ChopinStoreFlow
      };
      return contents[this.app];
    }
  }
};
</script>
