import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { chopinApiUrl } from "@/lib/utl.js";

Vue.use(VueAxios, axios);

class ChopinRequestError extends Error {
  constructor(requestName, status, message, storeId, userId, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ChopinRequestError);
    }

    this.name = "CustomError";
    // Custom debugging information
    this.requestName = requestName;
    this.status = status;
    this.message = message;
    this.storeId = storeId;
    this.userId = userId;
    this.date = new Date();
  }
}

const state = () => ({
  Stores: ["store1", "store2", "store3"],
  NewStoreId: "store1",
  StoreDetails: {},
  StoresFetched: false,
  PaymentMethods: []
});

const getters = {
  stores: (state) => {
    return state.Stores;
  },
  storesFetched: (state) => {
    return state.StoresFetched;
  },
  storeDetails: (state) => {
    return state.StoreDetails;
  },
  newStoreId: (state) => {
    return state.NewStoreId;
  },
  getPaymentMethods: (state) => {
    return state.PaymentMethods;
  }
};
const mutations = {
  updateStores(state, stores) {
    state.Stores = stores;
  },
  updateStore(state, payload) {
    state.StoreDetails[payload.storeId] = payload.storeDetail;
  },
  deleteStore(state, storeId) {
    const stores = [...state.Stores];
    state.Stores = stores.filter((x) => x != storeId);
  },
  setNewStoreId(state, storeId) {
    state.NewStoreId = storeId;
  },
  setPaymentMethods(state, payload) {
    state.PaymentMethods = payload;
  }
};
const actions = {
  UpdateNewStoreId({ commit }, storeId) {
    commit("setNewStoreId", storeId);
  },
  async fetchStores({ commit, state, rootGetters }) {
    try {
      const result = await Vue.axios.get(
        chopinApiUrl(),
        rootGetters["user/getUserATokenAuthHeader"]
      );
      commit("updateStores", result.data.stores);
      state.StoresFetched = true;
    } catch (err) {
      throw new Error(`Error fetching stores, error: ${err}`);
    }
  },
  async fetchStoreDetails({ commit, rootGetters }, payload) {
    const data = {
      headers: {
        Authorization: `bearer ${rootGetters["user/getUserAToken"]}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      data: {}
    };

    try {
      const result = await Vue.axios.get(chopinApiUrl(payload.storeId), data);
      commit("updateStore", {
        storeId: payload.storeId,
        storeDetail: result.data.store
      });
    } catch (err) {
      const resp = err.response || {};
      throw new ChopinRequestError(
        "Fetch store request",
        resp.status,
        resp.data.message,
        payload.storeId,
        rootGetters["user/userId"]
      );
    }
  },
  async updateStore({ rootGetters }, payload) {
    const storeId = payload.id;
    try {
      await Vue.axios.patch(
        chopinApiUrl(storeId),
        payload,
        rootGetters["user/getUserATokenAuthHeader"]
      );
    } catch (err) {
      const resp = err.response || {};
      throw new ChopinRequestError(
        "Update store request",
        resp.status,
        resp.data.message,
        storeId,
        rootGetters["user/userId"]
      );
    }
  },
  async deleteStore({ commit, rootGetters }, storeId) {
    const data = {
      headers: {
        Authorization: `bearer ${rootGetters["user/getUserAToken"]}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      data: {}
    };
    try {
      await Vue.axios.delete(chopinApiUrl(storeId), data);
      commit("deleteStore", storeId);
    } catch (err) {
      const resp = err.response || {};
      throw new ChopinRequestError(
        "Delete store request",
        resp.status,
        resp.data.message,
        storeId,
        rootGetters["user/userId"]
      );
    }
  },
  async getPaymentMethods({ commit, getters }) {
    if (getters.getPaymentMethods.length > 0) {
      return getters.getPaymentMethods;
    }
    const data = {
      headers: {
        Accept: "application/vnd.github.v3.raw"
      }
    };
    try {
      const result = await Vue.axios.get(
        process.env.VUE_APP_PAYMENT_METHODS_JSON_URL,
        data
      );
      commit("setPaymentMethods", result.data);
      return getters.getPaymentMethods;
    } catch (err) {
      const resp = err.response || {};
      throw new ChopinRequestError(
        "Error fetching payment methods.",
        resp.status,
        resp.data.message
      );
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
