<template>
  <div>
    <template v-if="formSection === 'top'">
      <Textfield v-if="ff.type === 'v-text-field'" :field="ff" :obj="el" />
      <Textarea
        v-if="ff.type === 'v-text-area'"
        :field="ff"
        :obj="el"
        :disabled="(custom['readonly'][el.name] || {})[ff.field]"
      />
      <Select
        v-if="ff.type === 'v-select'"
        :field="ff"
        :obj="el"
        :readonly="true"
      />
      <Checkbox
        v-if="ff.type === 'v-checkbox'"
        :field="ff"
        :obj="el"
        :readonly="(custom['readonly'][el.name] || {})[ff.field] || false"
        :disabled="(custom['readonly'][el.name] || {})[ff.field] || false"
        :rules="getCustomCheckboxRules(ff, el)"
      />
    </template>
    <template v-else>
      <Textfield
        v-if="ff.type === 'v-text-field'"
        :field="ff"
        :value="field.val"
      />
      <Textarea
        v-if="ff.type === 'v-text-area'"
        :field="ff"
        :value="field.val"
      />
      <Select v-if="ff.type === 'v-select'" :field="ff" :value="field.val" />
      <Checkbox
        v-if="ff.type === 'v-checkbox'"
        :field="ff"
        :value="field.val"
        :rules="getCustomCheckboxRules(ff, field.val)"
      />
    </template>
  </div>
</template>
<script>
import { InputMixin } from "@/lib/InputMixin.js";

import Textfield from "@/components/config/form/Textfield.vue";
import Textarea from "@/components/config/form/Textarea.vue";
import Select from "@/components/config/form/Select.vue";
import Checkbox from "@/components/config/form/Checkbox.vue";

export default {
  name: "UserInputs",
  mixins: [InputMixin],
  props: ["formSection", "ff", "el"],
  components: {
    Textfield,
    Select,
    Textarea,
    Checkbox
  },
  data() {
    const customRules = {
      ifHideThenNotRequired: (el) => {
        return (v) => {
          if (el.hide === true) {
            return !v || v === false || "Uncheck Hide";
          }
          return true;
        };
      },
      ifRequireThenNotHide: (el) => {
        return (v) => {
          if (el.required === true) {
            return !v || v === false || "Uncheck Required";
          }
          return true;
        };
      },
      ifValidateZipCodeThenNotHideAddress: () => {
        return (v) => {
          if (v === true && this.hasShippingValidation()) {
            return "Address required with shipping validation";
          }
          return true;
        };
      },
      ifValidateZipCodeThenRequireAddress: () => {
        return (v) => {
          if (v !== true && this.hasShippingValidation()) {
            return "Address required with shipping validation";
          }
          return true;
        };
      }
    };

    return {
      customRules: customRules,
      valid: true
    };
  },
  computed: {
    custom() {
      return {
        rules: {
          default: {
            hide: [this.customRules.ifRequireThenNotHide],
            required: [this.customRules.ifHideThenNotRequired]
          },
          address: {
            hide: [
              this.customRules.ifRequireThenNotHide,
              this.customRules.ifValidateZipCodeThenNotHideAddress
            ],
            required: [
              this.customRules.ifHideThenNotRequired,
              this.customRules.ifValidateZipCodeThenRequireAddress
            ]
          }
        },
        disabled: {
          name: true,
          email: true,
          phone: true,
          address: true,
          note: true
        },
        readonly: {
          name: {
            name: true,
            type: true,
            hide: true,
            required: false,
            description: true
          },
          email: {
            name: true,
            type: true,
            hide: true,
            required: true,
            description: true
          },
          phone: {
            name: true,
            type: true,
            hide: true,
            required: false,
            description: true
          },
          address: {
            name: true,
            type: true,
            hide: false,
            required: false,
            description: true
          },
          note: {
            name: true,
            type: true,
            hide: false,
            required: false,
            description: false
          }
        }
      };
    }
  },
  methods: {
    hasShippingValidation() {
      return Object.keys(this.obj.shipping_areas).length > 0;
    },
    getCustomCheckboxRules(ff, el) {
      const customRules = (this.custom.rules[el.name] ||
        this.custom.rules.default)[ff.field];
      if (customRules === undefined) {
        return [];
      }
      return customRules.map((x) => x(el));
    }
  }
};
</script>
