<template>
  <Explore />
</template>

<script>
import Explore from "@/components/Explore.vue";

export default {
  name: "ExploreView",
  props: {
    source: String
  },
  components: {
    Explore
  }
};
</script>
