<template>
  <v-text-field
    dense
    outlined
    flat
    :readonly="getReadonly(readonly)"
    :disabled="getDisabled(disabled)"
    :label="getLabel(optionName)"
    :hint="getHint(optionName)"
    :type="getDefaultFieldType()"
    :rules="getRules(optionName)"
    v-model="(value || field.obj || obj)[fieldName || field.field]"
  />
</template>

<script>
import { InputMixin } from "@/lib/InputMixin.js";

export default {
  name: "TextfieldComponent",
  mixins: [InputMixin],
  props: ["fieldName", "disabled", "optionsConfig", "optionName"]
};
</script>
