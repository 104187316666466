<template>
  <v-container>
    <div id="swagger-ui" ref="swagger-ui"></div>
  </v-container>
</template>

<style>
.information-container {
  visibility: hidden;
  height: 0;
}
</style>

<script>
import "swagger-ui/dist/swagger-ui.css";
import SwaggerUI from "swagger-ui";

export default {
  name: "Swagger",
  computed: {
    app() {
      return this.$route.params.name;
    }
  },
  mounted() {
    this.renderSwaggerDoc();
  },
  methods: {
    renderSwaggerDoc() {
      let url = `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/router/gcs/storage/v1/b/boo-${this.app}-dev/o/swagger%2fswagger.json?alt=media`;
      SwaggerUI({
        deepLinking: true,
        domNode: this.$refs["swagger-ui"],
        showExtensions: true,
        showCommonExtensions: true,
        url: url
      });
    }
  }
};
</script>
