<template>
  <v-container>
    <span v-html="content"></span>
  </v-container>
</template>

<script>
import md from "markdown-it";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Readme",
  data() {
    return {
      content: ""
    };
  },
  computed: {
    app() {
      return this.$route.params.name;
    }
  },
  mounted() {
    this.fetchServiceContent({ name: this.app, content: "readme" }).then(() => {
      this.updateContent();
    });
  },
  serverPrefetch() {},
  methods: {
    ...mapGetters("apps", ["getApps", "getDescriptions"]),
    ...mapActions("apps", ["fetchServiceContent"]),
    trimmed(raw) {
      // NOTE: grab only the first paragrah
      let n = raw.indexOf("\n#");
      return raw.slice(0, n - 1);
    },
    updateContent() {
      let renderer = md({
        html: true,
        linkify: true,
        typographer: true
      });
      let trimmed = this.trimmed(this.getDescriptions()[this.app]);
      let html = renderer.render(trimmed);
      this.content = html;
    }
  }
};
</script>
