<template>
  <ConfigMain
    configName="Chopin"
    createNewUrl="/flow/chopin-stores"
    :newId="newStoreId"
    :objects="stores"
    :fetched="storesFetched"
    :unblurredFunc="unblurred"
    :objectIsId="true"
    :getObjectName="getStoreName"
    :getObjectUrl="storeUrl"
  />
</template>

<script>
import ConfigMain from "@/components/config/ConfigMain.vue";
import { ChopinMixin } from "@/lib/ChopinMixin.js";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChopinConfigure",
  components: { ConfigMain },
  mixins: [ChopinMixin],
  data() {
    return {
      blurred: true
    };
  },
  computed: {
    ...mapGetters("chopin", [
      "stores",
      "storeDetails",
      "storesFetched",
      "newStoreId"
    ])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    ...mapActions("chopin", ["fetchStores"]),
    ...mapActions("postit", ["fetchEmails"]),
    getStoreName(storeId) {
      const storeDetails = this.storeDetails[storeId];
      let storeName = "";
      if (storeDetails !== undefined) {
        storeName = storeDetails.name || "";
      }
      return storeName;
    },
    async unblurred() {
      await this.auth0GetToken();
      await Promise.all([this.fetchStores(), this.fetchEmails()]);
    }
  }
};
</script>
