<template>
  <v-container>
    <component :is="componentToLoad"> </component>
  </v-container>
</template>

<script>
import GoldfishConfigure from "@/components/app/GoldfishConfigure.vue";
import PostItConfigure from "@/components/app/PostItConfigure.vue";
import ChopinConfigure from "@/components/app/ChopinConfigure.vue";
import WaitressConfigure from "@/components/app/WaitressConfigure.vue";
import NoAppConfigure from "@/components/app/NoAppConfigure.vue";

export default {
  name: "AppConfigure",
  components: {
    PostItConfigure,
    ChopinConfigure,
    WaitressConfigure,
    GoldfishConfigure,
    NoAppConfigure
  },
  data() {
    return {
      content: ""
    };
  },
  computed: {
    app() {
      return this.$route.params.name;
    },
    componentToLoad() {
      let contents = {
        "post-it": PostItConfigure,
        chopin: ChopinConfigure,
        waitress: WaitressConfigure,
        goldfish: GoldfishConfigure
      };
      return contents[this.app] || NoAppConfigure;
    }
  }
};
</script>
