import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";

import Explore from "@/views/Explore.vue";
import Settings from "@/views/Settings.vue";
import Flow from "@/components/flow/Flow.vue";
import CreateFlow from "@/components/flow/CreateFlow.vue";
import App from "@/components/app/App.vue";
import AppConfigure from "@/components/app/AppConfigure.vue";
import Readme from "@/components/app/Readme.vue";
import Swagger from "@/components/app/Swagger.vue";
import UserAuthToken from "@/components/user/UserAuthToken.vue";
import Billing from "@/components/user/Billing.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Explore",
    component: Explore
  },
  {
    path: "/settings",
    props: true,
    component: Settings,
    children: [
      {
        path: "",
        component: UserAuthToken
      },
      {
        path: "billing",
        component: Billing
      }
    ]
  },
  {
    path: "/apps",
    name: "Apps",
    component: Explore
  },
  {
    path: "/app",
    component: App
  },
  {
    path: "/app/:name",
    component: App,
    props: true,
    children: [
      {
        path: "about",
        component: Readme
      },
      {
        path: "",
        component: AppConfigure
      },
      {
        path: "swagger",
        component: Swagger
      }
    ]
  },
  {
    path: "/flow/:name",
    component: Flow,
    props: true,
    children: [
      {
        path: "",
        component: CreateFlow
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Configuration VueGtag
Vue.use(
  VueGtag,
  {
    config: { id: "G-NL7DC13GCL" }
  },
  router
);

export default router;
