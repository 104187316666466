<template>
  <v-container>
    <v-alert
      type="error"
      transition="slide-y-transition"
      border="left"
      dismissible
      outlined
      :value="hitASnag"
    >
      <v-row class="title">Oops, we've hit a snag!</v-row>
      <v-row class="py-1">
        <span>
          We are not able to save your update. Please see details below or
          contact our awesome developer at
          <a
            href="mailto:apiobuild@gmail.com"
            target="_blank"
            style="color: #00bcd4"
          >
            apiobuild@gmail.com
          </a>
          .
        </span>
      </v-row>
      <v-row>Message: {{ error }}</v-row>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: "UpdateAlert",
  props: ["hitASnag", "error"]
};
</script>
