import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

const state = () => ({
  Apps: [],
  Descriptions: {},
  Documentations: {}
});

const getters = {
  getApps: (state) => {
    return state.Apps;
  },
  getDescriptions: (state) => {
    return state.Descriptions;
  },
  getDocumentations: (state) => {
    return state.Documentations;
  },
  getDetails: (state) => (appName) => {
    const filteredApps = state.Apps.filter((x) => x.Name === appName);
    if (filteredApps) {
      const details = filteredApps[0];
      if (!details) {
        return { Name: "App", ImageUrl: null };
      }
      if (details.Name) {
        details.Name = details.Name.replace(/-/g, " ").replace(/_/g, " ");
      }

      return details;
    }
  }
};

const mutations = {
  updateApps(state, apps) {
    state.Apps = apps;
  },
  updateDescriptions(state, payload) {
    state.Descriptions[payload.service] = payload.content;
  },
  updateDocumentations(state, payload) {
    state.Documentations[payload.service] = payload.content;
  }
};

const actions = {
  async fetchApps({ commit }) {
    await Vue.axios
      .get(process.env.VUE_APP_APP_TILES_DATA_URL)
      .then((result) => {
        commit("updateApps", result.data.data);
      })
      .catch((error) => {
        throw new Error(`API ${error}`);
      });
  },
  fetchServiceContent({ commit }, payload) {
    let service = payload["name"];
    let content = payload["content"];

    let path = "";
    let commitFunction = "";
    let headers = {};

    switch (content) {
      case "swagger":
        path = `router/gcs/storage/v1/b/boo-${service}-dev/o/swagger%2fswagger.json?alt=media`;
        commitFunction = "updateDocumentations";
        break;
      case "readme":
        path = `router/github/repos/apiobuild/${service}/contents/README.md`;
        commitFunction = "updateDescriptions";
        headers = {
          headers: {
            Accept: "application/vnd.github.v3.raw"
          }
        };
        break;
      default:
        throw new Error(
          `Error fetching github content, content type specified: ${content} not implemented.`
        );
    }
    let url = `${process.env.VUE_APP_TRAMPOLINE_SERVICE_URL}/${path}`;
    return Vue.axios
      .get(url, headers)
      .then((result) => {
        let payload = {
          service: service,
          content: result.data
        };
        commit(commitFunction, payload);
      })
      .catch((error) => {
        throw new Error(
          `Error fetching github content ${content} for service ${service}, error: ${error}`
        );
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
