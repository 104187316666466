<template>
  <v-expansion-panels multiple focusable v-model="panel">
    <v-expansion-panel
      v-for="(form, i) in forms"
      :key="i"
      @click="validateInput()"
    >
      <v-expansion-panel-header>
        <v-row align="center">
          <v-col class="col-9 pa-0">
            {{ form.name }}
          </v-col>
          <v-col v-if="form.docs" class="col-3 pa-0">
            <a :href="form.docs" target="_blank" style="text-decoration: none">
              <v-btn small outlined color="secondary"> Get Help </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="py-0 pt-8">
          <v-col cols="12" class="px-2" v-if="form.description">
            <span v-html="form.description"></span>
          </v-col>
          <template v-for="(field, fi) in form.fields">
            <v-col class="px-1 py-1" :cols="field.cols || 12" :key="fi">
              <v-form
                style="width: 100%"
                v-model="valid"
                :ref="`${formName}-${field.name || field.type}-${i}`"
                :key="fi"
              >
                <v-divider class="py-1" v-if="field.type === 'v-divider'" />
                <Textfield
                  v-if="field.type === 'v-text-field'"
                  :field="field"
                  :obj="obj"
                />
                <!-- NOTE: text area needs value otherwise it cannot update -->
                <Textarea
                  v-if="field.type === 'v-textarea'"
                  :field="field"
                  :obj="obj"
                  :value="field.val"
                />
                <Swatch
                  v-if="field.type === 'swatch'"
                  :field="field"
                  :obj="obj"
                />
                <Checkbox
                  v-if="field.type === 'v-checkbox'"
                  :field="field"
                  :obj="obj"
                />
                <Select
                  v-if="field.type === 'v-select'"
                  :field="field"
                  :obj="obj"
                />
                <Combobox
                  v-if="field.type === 'v-combobox'"
                  :field="field"
                  :obj="obj"
                />
                <ComboboxWithLoad
                  v-if="field.type === 'v-combobox-with-load'"
                  :field="field"
                  :obj="obj"
                />
                <NumberDial
                  v-if="field.type === 'number-dial'"
                  :field="field"
                  :obj="obj"
                />
              </v-form>
              <CompoundForm
                v-if="isCompoundForm(field)"
                :ref="`${formName}-${field.name}-${i}`"
                :refName="`${formName}-${field.name}-child-${i}`"
                :field="field"
                :obj="obj"
              />
            </v-col>
          </template>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { ConfigureMixin } from "@/lib/ConfigureMixin.js";

export default {
  name: "ExpansionPanels",
  mixins: [ConfigureMixin],
  props: ["forms", "obj"],
  methods: {
    isCompoundForm(field) {
      return [
        "add-to-object",
        "add-to-array",
        "user_inputs",
        "callbacks_config"
      ].includes(field.type);
    }
  }
};
</script>
