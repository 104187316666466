<template>
  <v-container>
    <v-card>
      <v-card-title justify-center>Authorize Gmail Account</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="text--primary body-1">
              In order to enable gmail automation functinoality. This app
              requires you to allow us to send email on your behalf. Click on
              the
              <v-btn fab x-small color="success">
                <v-icon>fa-plus </v-icon>
              </v-btn>
              below. <br />You may remove permission at any time by clicking
              <v-btn fab x-small outlined color="error" target="_blank">
                <v-icon>fa-times</v-icon>
              </v-btn>
              next to the email your want to remove permission. If you have
              previous email automation workflows configured, those will stop
              working immediately. <br />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-container class="px-0">
      <v-row class="text-center" align="center" justify="center">
        <v-col class="col-md-4 col-12">
          <v-btn
            fab
            block
            color="info"
            tile
            @click="unblurred()"
            v-if="!emailsFetched"
          >
            View Emails
          </v-btn>
        </v-col>
      </v-row>
      <v-container
        v-bind:class="{ blurred: !emailsFetched }"
        class="pa-0"
        fluid
        full-width
      >
        <v-card :disabled="!emailsFetched">
          <v-list-item v-for="email in emails" :key="email">
            <v-col class="col-2">
              <v-list-item-icon v-if="newEmailId === email">
                <v-chip class="ma-2" color="success"> New </v-chip>
              </v-list-item-icon>
            </v-col>
            <v-list-item-content>
              {{ email }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                outlined
                fab
                small
                color="error"
                target="_blank"
                @click="removeEmail(email)"
              >
                <v-icon>fa-times</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="">
            <v-col class="text-center">
              <v-btn fab color="success" @click="addEmail()">
                <v-icon>fa-plus </v-icon>
              </v-btn>
            </v-col>
          </v-list-item>
        </v-card>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import GAuth from "vue-google-oauth2";

Vue.use(GAuth, {
  clientId: process.env.VUE_APP_GOOGLE_APP_CLIENT_ID,
  scope: "profile email https://www.googleapis.com/auth/gmail.send",
  prompt: "consent"
});

export default {
  name: "PostItConfigure",
  data() {
    return {
      blurred: false,
      newEmail: ""
    };
  },
  computed: {
    ...mapGetters("postit", ["emails", "newEmailId", "emailsFetched"])
  },
  methods: {
    ...mapActions("user", ["auth0GetToken"]),
    ...mapActions("postit", [
      "fetchEmails",
      "googleOAuthConsent",
      "addWatch",
      "deleteWatch"
    ]),
    async unblurred() {
      await this.auth0GetToken();
      await this.fetchEmails();
      this.$forceUpdate();
    },
    async addEmail() {
      let authCode = await this.$gAuth.getAuthCode();
      await this.addWatch({ token: authCode });

      await this.fetchEmails();
      this.$forceUpdate();
    },
    async removeEmail(email) {
      await this.deleteWatch({ email: email });

      await this.fetchEmails();
      this.$forceUpdate();
    }
  }
};
</script>
