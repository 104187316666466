<template>
  <v-container float style="position: absolute; top: -75px; z-index: 10">
    <v-btn
      v-show="!expand"
      absolute
      top
      right
      fab
      dark
      small
      color="secondary"
      style="top: 20px"
      @click="showNotification()"
    >
      <v-icon small>fa fa-bell</v-icon>
    </v-btn>
    <v-btn
      v-show="expand"
      absolute
      top
      right
      fab
      dark
      small
      color="error"
      style="top: 20px"
      @click="expand = !expand"
    >
      <v-icon small>fa fa-times</v-icon>
    </v-btn>
    <v-container>
      <v-row>
        <v-expand-transition>
          <v-col class="col-10" v-show="expand">
            <v-alert
              color="info"
              border="left"
              elevation="2"
              colored-border
              icon="fa fa-bullhorn"
              :type="message.type"
              v-for="message in messages"
              :key="message.name"
            >
              <span v-html="message.message"></span>
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      expand: false,
      messages: [
        {
          type: "info",
          message: `You can now preview your markdown content.
          Go to <a href="/app/chopin">Chopin App</a> and try now.`
        }
      ]
    };
  },
  methods: {
    showNotification() {
      this.expand = true;
      setTimeout(() => {
        this.expand = false;
      }, 5000);
    }
  }
};
</script>
